<template>
  <div class="bg-white m-3 mb-5 animate__animated animate__fadeInUp">
    <div class="wrapper-image" v-if="type!=='paragraph'" :style="{backgroundImage: `url(${image})`}">

    </div>
    <div v-else>
      <center class="p-4">
        <img src="../assets/quote.svg" class="opacity-50">
      </center>
    </div>
    <div class="p-3">
      <p class="text-black-50" v-if="type!=='paragraph'">{{date}}</p>
      <h4 v-if="type!=='paragraph'"><strong><a style="color:black;" :href="'/blog/'+pubId">{{title}}</a></strong></h4>
      <h4 v-else style="text-align: center" class="py-3"><strong>{{title}}</strong></h4>
      <p :style="{textAlign:type!=='paragraph'?'left':'center'}">{{text}}</p>
      <div class="d-flex flex-wrap cat-wrapper" v-if="type!=='paragraph'">
        <a style="padding-left:0!important;" v-for="(cat,index) in cats" :key="index" >{{cat}} {{index===(cats.length-1)?'':','}}</a>
      </div>
    </div>
  </div>
</template>
<script>
 export  default {
   props:{
     type:String,
     date:String,
     image:String,
     title:String,
     text:String,
     cats:Array,
     pubId:String
   }
 }
</script>
<style scoped>
.shadow{
  box-shadow:  0 2px 3px rgb(0, 0, 0 , 5%)
}
.wrapper-image{
  /*background-image: url("../assets/dummyimage.jpg");*/
  width: 100%;
  height: 300px;
  background-position: center;
  background-size: cover;
}
.cat-wrapper a{
  padding:0 10px;
  color:var(--deep-blue-color);
  font-weight: bold;
}
</style>
