<template>
  <div >
    <div class="container" style="z-index: 2;position: relative">
      <div style="" class=" w-100 d-flex justify-content-between align-items-center">
        <div></div>
        <h2 class="text-white m-0 mt-4">Our Blog</h2>
        <div></div>
      </div>
      <hr style="background-color: rgba(255,255,255,0.5);opacity:0.5"/>
    </div>
    <Header/>
    <div class="page-head">
      <div class="container d-flex flex-column justify-content-center align-items-start" style="z-index: 1;position: relative;height: 100%">
        <div style="max-width: 700px">
          <h3 class="text-white ">{{blog.title}}</h3>
          <p class="text-white fw-lighter mt-3" v-html="truncateString(blog.subtitle,250)">
          </p>
          <p class="text-white-50">{{new Date(blog.created_at).toDateString()}}</p>
          <small class="text-white-50">{{totalView}} Views</small>
        </div>
      </div>
    </div>
    <!--    <img src="../assets/download 1_place_1.svg" class="w-100 image-design1"/>-->
    <Bizarresvg color="#fff" svgclass="w-100 image-design1 imd1"/>
    <div>
      <br/>
      <div class="container post-content" style="max-width: 960px">
        <h2 class="fw-lighter text-decoration-underline">{{blog.title}}</h2>
        <span class="mt-2 mb-2"><span class="text-black-50">{{new Date(blog.created_at).toDateString()}}</span> 
        <span v-if="blog.categorie">
          In 
        <span v-for="(c,i) in JSON.parse(blog.categorie)" :key="'c'+i">
            {{i==(JSON.parse(blog.categorie).length-1)?c:c+','}}
          </span>
        </span>
          </span>
        <img class="mt-3" :src=image width="100%"/>
        <p v-html="blog.subtitle"></p> 
        <br/>
        <br/>
        <div>
          <strong :style="{color:iLike?'var(--orange-color)':'black',cursor:'pointer'}" @click="addLike"><i class="fa fa-thumbs-up"></i> <span>Like ({{totalLike}})</span></strong>
        </div>

        </div>

       
      <Bizarresvg color="#f2f2f2" svgclass="w-100 mt-1"/>
      <div style="background-color: #f2f2f2">
        <div class="container p-5" style="max-width: 960px;">
          <h3>{{size}} comments</h3>
          <div class="d-flex flex-column mt-4">
            <comment v-for="(com, i) in comments" :key="i" :email="com.email" :name="com.name" :date="com.date" :comment="com.message"></comment>
          </div>
          <br/>
          <div class="float-end">
            <button class="btn btn-lg add-comment" @click="commentMode=true;" >Add your comment</button>
          </div>
          <br/>
          <br/>
          <br/>
        </div>

        <Bizarresvg color="#fff" svgclass="w-100 mt-1"/>
      </div>

    </div>

    <br/>
    <posts-footer></posts-footer>
    <Footer/>
    <div class="comment-overlay animate__animated animate__fadeIn d-flex justify-content-center align-items-center" v-if="commentMode">
      <div class="container" style="max-width: 960px">
        <form @submit="submit">
          <h3 class="text-white">What do you have to say about <span class="quoted" style="color: var(--orange-color)">{{blog.title}}</span>?</h3>
          <h5 class="mt-5 text-white fw-lighter"> Write a comment</h5>
          <input type="email" placeholder="Your Email"  class=" mt-5 form-control mt-2 bg-transparent border-top-0 border-end-0 border-start-0" style="border-radius: 0;color: white;" v-model="email" required>
          <input type="text" placeholder="Your Name" class=" mt-5 form-control mt-2 bg-transparent border-top-0 border-end-0 border-start-0" style="border-radius: 0;color: white" v-model="name" required>
          <textarea placeholder="Your Comment" type="text" class=" mt-5 form-control mt-2 bg-transparent border-top-0 border-end-0 border-start-0" style="border-radius: 0;color: white;height: 150px;resize: none" v-model="message" required></textarea>

          <br/>
          <p style="color:white;font-weight:bolder" v-if="submitting_contact_success">Message Submitted !</p>
          <p style="color:red;font-weight:bolder" v-if="submitting_contact_error">Sorry, that didn't work! Try again please</p>
          <div class="float-end">
            <span v-if="!loadingCommentary" class="text-white mx-4 " style="cursor: pointer" @click="commentMode=false"><i class="fa fa-times-circle mx-1"></i> Cancel</span>
            <button class="btn btn-lg add-comment" :disabled="loadingCommentary">{{loadingCommentary?"Loading...":"Comment Now"}}</button>
          </div>
        </form>
        <br/>
        <br/>
        <br/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Bizarresvg from "@/components/Bizarresvg";
import PostsFooter from "@/components/PostsFooter";
import Comment from "@/components/Comment";
import Footer from "@/components/Footer";

import {dataAxios, link, postAxios, getCurrentUserId} from "../../config/helpers"

export default {
  name:"BlogInside",
  components:{
    Footer,
    Comment,
    PostsFooter,
    Bizarresvg,
    Header
  },

  mounted() {
    postAxios('add/view', {
      blog_id : this.$route.params.id,
      user : getCurrentUserId()
    },(res)=>{
      console.log(res)
    })

    postAxios('all/view', {
      blog_id : this.$route.params.id
    },(res)=>{
      console.log(res)
      console.log(res)
      this.totalView = res.data
    })

    postAxios('all/like', {
      blog_id : this.$route.params.id
    },(res)=>{
      console.log(res)
      this.totalLike = res.data
    })

    postAxios('show/like', {
      blog_id : this.$route.params.id,
      user : getCurrentUserId()
    }, (res)=>{
      this.iLike = res.data
    })

    dataAxios('blog/'+this.$route.params.id, (res)=>{
      this.blog = res.data;
      this.image = link.replace('api/', 'storage/')+res.data.logo.split("\\").join("/")
    }),
   
    dataAxios('all/comment/'+this.$route.params.id, (res)=>{
      this.comments = Object.values(res.data).map(rep=>{
        return{
          name: rep.name,
          email: rep.email,
          message: rep.message,
          date: new Date(rep.created_at).toDateString()+' @ '+new Date(rep.created_at).toTimeString().split(' ')[0]
        }
      }),
      this.size = res.data.length
    },()=>{
    })
    const context=this;
    context.initImd1Responsive();
     window.onresize = function(){
        context.initImd1Responsive();
      }
  },
  methods:{
     initImd1Responsive(){ //to ensure that the responsive of the svg design of slider is okay
     window.$('.imd1').css('margin-top',(0-window.$('.imd1').height()-20))
    },
    truncateString(str, num) {
      if(!str){
        return '';
      }
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
    submit(event){
      event.preventDefault();
      this.loadingCommentary=true;
      const context = this;
      postAxios('comment', {
        name : context.name,
        email : context.email,
        message : context.message,
        blog_id : this.$route.params.id
      }, ()=>{
        context.name=null;
        context.email=null;
        context.message=null;
        context.submitting_contact_success="Message Submitted!";
        dataAxios('all/comment/'+this.$route.params.id, (res)=>{
          this.comments = Object.values(res.data).map(rep=>{
            return{
              name: rep.name,
              email: rep.email,
              message: rep.message,
              date: new Date(rep.created_at).toDateString()+' @ '+new Date(rep.created_at).toTimeString().split(' ')[0]
            }
          })
          this.size = res.data.length
        })
      }, ()=>{
        context.submitting_contact=false;
         context.loadingCommentary=false;
         context.commentMode=false;
      }, ()=>{
        context.submitting_contact_error="Sorry, that didn't work! Try again please";

      })
    },

    addLike(eve){
      eve.preventDefault()
      postAxios('add/like', {
        like : !this.iLike,
        blog_id : this.$route.params.id,
        user : getCurrentUserId()
      }, (res)=>{
        this.iLike = res.data
        postAxios('all/like', {
          blog_id : this.$route.params.id
        },(res)=>{
          this.totalLike = res.data
        })
      })
    }
  },
  data(){
    return {
      commentMode:false,
      blog : [],
      image : "",
      comments: [],
      size: "",
      loadingCommentary:false,
      iLike:false,
      totalLike: 0,
      totalView: 0
    }
  }
}
</script>
<style scoped>
body{
  /*background: #19191b;*/
}
.page-head{
  margin-top:-150px;
  /*z-index: 0;*/
  position:relative;
  background-image: url("../assets/dummyimage.jpg");
  /*background-image: url("../assets/hero-bg.jpg");*/
  background-color: #151515;
  background-size: cover;
  background-repeat: no-repeat;background-position:center;
  height: 700px;
}
.blog-header-image{
  background-image: url("../assets/dummyimage.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.blog-header-image:last-child{

  background-image: url("https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80");
}
.page-head::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #151515;
  opacity: 0.79;
}
.blog-header-image >div{
  transition: all ease 0.7s;
  display: flex;
  height: 275px;
  width: 100%;
  background: linear-gradient(transparent,rgba(0,0,0,0.8),  rgba(0,0,0,0.8));

}
.blog-header-image >div:hover{
  background:  rgba(0,0,0,0.85);

  /*opacity: 0.7;*/

  /*background: radial-gradient(transparent,rgba(0,0,0,0.4),  rgba(0,0,0,0.8));*/
}

.blog-header-image.big > div{
  height: 550px;
}
.blog-header-image>div:hover{

}
.thetag{
  position: relative;
  z-index: 2;
}
.image-design1{
  position: relative;

}
video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: 600px   !important;
  position: absolute;
  object-fit: cover;
}
.post-content *{
  font-weight: lighter;
}
.add-comment{
  border-radius: 0;
  background-color: var(--deep-blue-color);
  color: white;
}
.add-comment:hover{
  background-color: var(--orange-color);
  color: white;
}
.comment-overlay{
  z-index: 1999;
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color: rgba(0,30,60,0.7);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
.quoted::after{
   content: url("../assets/quote.white.svg");
  height: 10px;
  margin-left: 10px;
}
</style>
