<template>
  <div class="home">

    <div class="topBackgroundVideo">
      
      <Header/>
      <div class="slider-container" style="margin-top:-65px;z-index: 1;position:relative">
        <div class="slide" v-for="(slider,i) in sliders" :key="'slider_'+i"
             :style="{backgroundImage: `url(${slider})`}"></div>
        <div class="overlay"></div>
        <div class="content-section container">
          <div class="row" style="height: 100%">
            <div
                class="col-xl-4 col-lg-5 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-start"
                style="height: 100%">
              <h4 class="fw-bolder text-white" style="margin-top:-2em;font-size: 3em">
                Summer Bootcamp
              </h4>
              <p class="text-white fw-light" style="max-width: 400px">

                Apply for our technology summer boot camp.
              </p>
              <a class="btn text-white px-4 py-2 mt-2" href="https://docs.google.com/forms/d/11xRAdFSbfMRgWwI1DEi4NO5KawTWEyqQxCrl9FSEL7s/edit?usp=sharing" style="background: var(--orange-color)">Apply Now <i
                  class="mx-2 fa fa-chevron-right"></i></a>
            </div>

          </div>
        </div>
        <div class="controls-container">
          <div class="control" v-for="(slider,i) in sliders" :key="'controls_'+i"></div>
        </div>
      </div>
      <img src="../assets/download 1_place_1.svg" class="w-100 image-design1 imd1"/>
    </div>
    <div class="_background bg-image">
      <div class="container fw-lighter  " style="max-width: 960px">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-sm-7 px-3 ">
          
            <h4><strong style="color: var(--deep-blue-color)" >ABOUT THE BOOTCAMP</strong></h4>
            <div style="width: 50%;height: 5px;background-color: var(--orange-color)" class="mb-2"></div>
            <p class="mt-3" style="padding-right: 40px">Our Technology Summer Bootcamp is a training programme that
              seeks to upskill tech-oriented high school or university-level Cameroonians. Spaces are available for 20
              participants in Yaoundé and 10 in Bamenda. Students on the programme will take courses on either web or
              mobile development over the duration of the programme, followed by a month of working on a real-world
              project so as to consolidate the new knowledge and technical skills that they have acquired.
              We offer programmes that cover the three tracks presented below.</p>
            <!-- <button class="btn text-white px-4 py-2 mt-2" style="background: var(--orange-color)">Find Out More <i
                class="mx-2 fa fa-chevron-right"></i></button> -->
          </div>
          <div class="col-sm-12 col-md-6 col-sm-7 px-2">
            <div class="_background bc-intro-cover" id="abt-the-bc">

            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    <div class="bg-white">
      <div class="blur-bg-image _background " style="z-index: 0!important;position: relative">

      </div>
      <div class="wrp  d-flex justify-content-center flex-column py-5">
        <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-6 ">

                <div class="slickers_bootcamp mx-sm-5 mx-sm-1 px-sm-1 px-md-4">

                  <div class="slickItem" v-for="(slider,i) in techlist" :key="'slider_'+i" :style="{backgroundImage: `url(${slider.image})`}"></div>
                </div>

              </div>
              <div class="col-sm-12 col-md-6">
               <div  v-for="(i,index) in bootcamps" :key="index">
                  <div class="wrp-text animate__animated animate__fadeInRight" v-if="tech_pos===index">
                  <h5>{{i.name}}</h5>
                  <span v-html="i.content"></span>
                </div>
               </div>

              </div>
            </div>
        </div>

      </div>
      <!--      -->
      <!--      <img src="../assets/download 1_place_1.svg" class="w-100 image-design1" style="margin-top: -15em!important;"/>-->

      <!--      <Bizarresvg color="#fff" svgclass="w-100 mt-1 bz2"/>-->
      <!--      <div class="bg-white p-3" style="z-index: 2;position:relative;margin-top: -50px;background-color:rgba(252,127,3,0.8);" >-->
      <!--        <h2>This is it</h2>-->
      <!--      </div>-->

      <div class="bg-white" style="z-index: 2;position: relative;height: 50px;">
        <!--        <h1>sd</h1>-->
      </div>
      <div class="_background bg-image">
        <div class="container fw-lighter  " style="max-width: 960px">
          <div class="row">

            <div class="col-sm-12 col-md-7 col-sm-7 px-2">
              <div class="row">
                <div class=" col-sm-6">
                  <div v-if="(teaching.length%2==1)">
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                  </div>
                <div class="shadow bg-white p-4 mt-4"  v-for="teach in teaching.filter((t,i)=>(i%2!=0))" :key="teach" style="border-radius: 5px;" >
                  
                     <i :class=" teach.fa_icon+' mb-3 fs-1'" style="color: var(--orange-color)"></i>
                    <br/>
                    <strong style="color: var(--deep-blue-color);margin-top: 20px;">{{teach.title}}</strong>
                    <p class="text-black-50">{{teach.title!=teach.description?teach.description:''}}</p>
                    <a class="text-white btn px-4 new-btn-type" style="" href="https://docs.google.com/forms/d/11xRAdFSbfMRgWwI1DEi4NO5KawTWEyqQxCrl9FSEL7s/edit?usp=sharing">Apply Now <i
                        class="fa fa-chevron-right p-2 bg-white"></i></a>
                  </div>
              </div>
                <div class=" col-sm-6">
                   <div v-if="(teaching.length%2==0)">
                      <br/>
                      <br/>
                      <br/>
                      <br/>
                  </div>
                  <div class="shadow bg-white p-4 mt-4"  v-for="teach in teaching.filter((t,i)=>(i%2!=1))" :key="teach" style="border-radius: 5px;">
                   
                    <i :class=" teach.fa_icon+' mb-3 fs-1'" style="color: var(--orange-color)"></i>
                    <br/>
                    <strong style="color: var(--deep-blue-color);margin-top: 20px;">{{teach.title}}</strong>
                    <p class="text-black-50">{{teach.title!=teach.description?teach.description:''}}</p>
                    <a class="text-white btn px-4 new-btn-type" style="" href="https://docs.google.com/forms/d/11xRAdFSbfMRgWwI1DEi4NO5KawTWEyqQxCrl9FSEL7s/edit?usp=sharing">Apply Now <i
                        class="fa fa-chevron-right p-2 bg-white"></i></a>
                  </div>
                
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-5 col-sm-7 px-3 d-flex flex-column justify-content-center align-items-start">
              <h6 class="mt-4"><strong style="color: var(--deep-blue-color)">ABOUT THE BOOTCAMP</strong></h6>
              <h4><strong style="color: var(--orange-color)">Our Teaching Methods </strong></h4>
              <p class="mt-3" style="padding-right: 40px">The approaches we use are simple, professional, basic and
                efficient.We do our very best possible to ensure maximum comprehension, maximum interpretation and hence
                maximum results </p>
              <!-- <button class="btn text-white px-4 py-2 mt-2" style="background: var(--orange-color)">Find Out More <i
                  class="mx-2 fa fa-chevron-right"></i></button> -->
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>



    <div class="w-100" >
        <div class="stdents-remark blur-bg-image _background animate__animated animate__fadeIn" style="z-index: 0!important;position: relative" >
<!-- :style="{backgroundImage: `url(${slider.image})`}" -->
        </div>
      </div>
      <div class="wrp  d-flex justify-content-center flex-column py-5">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-7 ">

              <div class="slickers_bootcamp_students px-sm-1 px-md-4 mx-sm-1 mx-md-5 py-3">

                <div class="slickItem" v-for="(slider,i) in studenlist" :key="'slider_'+i" :style="{backgroundImage: `url(${slider.image})`,backgroundPosition:'0% 20%',height:'400px'}">
                  <div class="d-flex justify-content-end flex-column align-items-start">
                    <h4 class="p-2 text-white btn " style="background: var(--deep-blue-color);border-radius: 0;margin-top:20px;">{{slider.name}}</h4>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-sm-12 col-md-5 d-flex flex-column justify-content-center">
              <div  v-for="(std,i) in studenlist" :key="std.name" >
                <div class="wrp-text animate__animated animate__fadeInRight" v-if="studen_pos===i">
                  <h5>{{std.text}}</h5>
                </div>
              </div>



            </div>
          </div>
        </div>

      </div>








      <div class="_background bg-image" style="margin-top: -5px;z-index: 2;position: relative;">
      <div class="container fw-lighter  " >
         <div class="container pb-5 " style="max-width: 960px">
                    <img src="/pdicon.png" height="100"/>
                    <h2 style="color:var(--deep-blue-color)">Our Tutors</h2>
                    <div style="background-color:var(--orange-color);height:6px;width:90px;"/>
                  
                    <div class="row mt-5" v-for="(tutor,index) in tutors" :key="'tutor-'+tutor.id">
                      <div class="col-sm-12 col-md-4 " v-if="index%2==0">
                      <div style="height:250px;background-position:top;background-repeat:no-repeat;background-size:cover;" :style="{backgroundImage:`url('${tutor.image}')`}">

                      </div>
                      <center><small class=" text-black-50 mt-3">{{tutor.name}}</small></center>
                      </div>
                      <div class="col-sm-12 col-md-8 ">
                        <h3 class=" "><strong>{{tutor.name}}</strong></h3>

                    <div style="background-color:var(--orange-color);height:6px;width:90px;margin-top:-3px;margin-bottom:20px;"/>
                          <p class="roboto-inside" v-html="tutor.text"></p>
                   
                      </div>
                      <div class="col-sm-12 col-md-4 " v-if="index%2==1">
                      <div style="height:250px;background-position:top;background-repeat:no-repeat;background-size:cover;" :style="{backgroundImage:`url('${tutor.image}')`}">

                      </div>
                      <center><small class=" text-black-50 mt-3">{{tutor.name}}</small></center>
                      </div>
                     </div>

                   
                </div>
        <div class="container pb-5 " style="max-width: 960px">
          <div class="row">
          <div class="col-sm-12 col-md-6 col-sm-7 px-3 d-flex flex-column justify-content-center align-items-start">
            <h4><strong style="color: var(--deep-blue-color)">Become a tutor</strong></h4>
            <div style="width: 20%;height: 3px;background-color: var(--orange-color)" class="mb-2"></div>
<!--            theteam-->
            <p class="mt-3" style="padding-right: 40px">
              Become a tutor at PremierDev for the next bootcamp edition. 
            </p>
            <a target="_blank" href="mailto:inquiries@premierdev.org" class="btn text-white px-4 py-2 mt-2" style="background: var(--deep-blue-color)">Get in touch <i
                class="mx-2 fa fa-chevron-right"></i></a>
          </div>
          <div class="col-sm-12 col-md-6 col-sm-7 px-2">
            
          </div>
        </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    </div>
    <Footer/>
  </div>
</template>

<script>

import Header from "@/components/Header";
import Footer from "@/components/Footer";

import {dataAxios, link} from "../../config/helpers"

export default {
  name: 'Home',
  components: {
    Footer,
    Header
  },
  data() {
    return {
      tutors:[],
      sliders: [
        // /Users/macbookpro/Desktop/projects/premierdev/src/assets/newphotos/IMG_0012.jpeg
        '/assets/newphotos/IMG_0012.jpeg',
        '/assets/newphotos/IMG_0027.jpeg',
        // '/assets/newphotos/IMG_0031.jpeg',
        '/assets/newphotos/IMG_0072.jpeg',
        // '/assets/home-slider/b1.jpg',
        // '/assets/home-slider/b2.jpg',
        // '/assets/home-slider/00eb7f11-356f-4ef7-8a0d-6fc9897bc713.JPG',
        // '/assets/home-slider/28ce4429-dda6-4569-8a5c-a6efdbdfaf09.JPG',
        // '/assets/home-slider/713096c5-71b9-4050-adf2-594d397c6536.JPG',
      ],
      slidersTo: [],
      teaching: [],
      /*techlist: [
        'https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80', 'https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80', 'https://images.unsplash.com/photo-1504707748692-419802cf939d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1330&q=80',
     ],*/
      techlist: [],
      slickObject: null,
      tech_pos:0,
      bootcamps : [],
      studenlist:[],
      studen_pos:0,
    }
  },
  mounted() {
    const context=this;
    dataAxios('bootcamp', (res)=>{
      context.bootcamps = res.data.map(bootcamp=>{
        return{
          name: bootcamp.name,
          content: bootcamp.content
        }
      })
      context.techlist = res.data.map(bootcamp=>{
        return{
          image: link.replace('api/', 'storage/')+bootcamp.image.split("\\").join("/")
        }
      })
      setTimeout(function(){
          context.initSlick()
        }, 500);

      dataAxios('teaching', (resp)=>{
        // console.log(resp)
        context.teaching = resp.data.map(teaching=>{
          return{
            title: teaching.title,
            description: teaching.description,
            fa_icon:teaching.fa_icon
          }
        })
    
      })
       dataAxios('tutors', (resp)=>{
        // console.log(resp)
        context.tutors = resp.data.map(tutor=>{
          tutor.image = link.replace('api/', 'storage/')+tutor.image.split("\\").join("/")
          return tutor;
        })
      })
      
        dataAxios('student', (res)=>{
        context.studenlist = res.data.map(student=>{
          return{
            name:student.name,
            text:student.description,
            image:link.replace('api/', 'storage/')+student.image.split("\\").join("/")
          } 
        })
        
         setTimeout(function(){
          context.initSlick2()
        }, 500);
        })
    })
    this.initSlider();
    setTimeout(function(){
     context.initImd1Responsive();
    },1500)
    window.onresize = function(){
      context.initImd1Responsive();
    }
    // var $ds = window.$('.image-design1');
    //       for(var i =0;i<$ds.length;i++){
    //       //  window.$($ds[i]).css('margin-top',(0-window.$($ds[i]).height()))
    // }
  },
  methods: {
    initImd1Responsive(){ //to ensure that the responsive of the svg design of slider is okay
     window.$('.imd1').css('margin-top',(0-window.$('.imd1').height()-20))
    },
    initSlick2(){
      let context=this;
   this.slickObject = window.$('.slickers_bootcamp_students').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '90px',
        focusOnSelect: true,
        autoplay:true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // infinite: true,
              // dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });
     window.$('.slickers_bootcamp_students').on('afterChange', function(x,y,z){
        context.studen_pos =z;
      });
    },
    initSlick(){
      let context=this;
      this.slickObject = window.$('.slickers_bootcamp').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '60px',
        focusOnSelect: true,
        autoplay:true,
        autoplaySpeed: 10000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // infinite: true,
              // dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });
      window.$('.slickers_bootcamp').on('afterChange', function(x,y,z){
        // console.log(x,y);
        // console.log("SWIPE");
        // alert(x);
        context.tech_pos =z;
        // global.activeSlide = nextSlide;
        // global.changeSlides();
        // console.log(direction);
        // left
      });
    
    },
    initSlider() {
      //slider https://codepen.io/FlorinPop17/pen/gOpWwrg
      const slides = document.querySelectorAll('.slide');
      const controls = document.querySelectorAll('.control');
      global.activeSlide = 0;
      let prevActive = 0;

      changeSlides();
      let int = setInterval(changeSlides, 4000);

      function changeSlides() {
        console.log(global.activeSlide, slides[global.activeSlide]);
        slides[prevActive].classList.remove('active');
        controls[prevActive].classList.remove('active');

        slides[global.activeSlide].classList.add('active');
        controls[global.activeSlide].classList.add('active');

        prevActive = global.activeSlide++;

        if (global.activeSlide >= slides.length) {
          global.activeSlide = 0;
        }

        // console.log(prevActive, global.activeSlide);
      }

      global.changeSlides = changeSlides;
      controls.forEach(control => {
        control.addEventListener('click', () => {
          let idx = [...controls].findIndex(c => c === control);
          global.activeSlide = idx;

          changeSlides();

          clearInterval(int);
          int = setInterval(changeSlides, 4000);
        });
      });
    }
  }
}
</script>
<style scoped>

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: 90vh;
  width: 100vw;
}

.slider-container h1 {
  color: #fff;
  font-size: 100px;
  letter-spacing: 5px;
  position: relative;
  z-index: 100;
  text-align: center;
}

.slider-container::after {
  background-color: #000;
  content: '';
  position: absolute;
  opacity: 0.3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  transform: scale(1.15);
  transition: opacity .6s ease;
}

.slide.active {
  animation: grow 4s linear forwards;
  opacity: 1;
}

@keyframes grow {
  0%, 20% {
    transform: scale(1);
  }

  75%, 100% {
    transform: scale(1.15);
  }
}

.controls-container {
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
  z-index: 2;
}

.control {
  background-color: #fff;
  cursor: pointer;
  opacity: 0.5;
  margin: 6px;
  height: 40px;
  width: 5px;
  transition: opacity 0.3s, background-color 0.3s, transform 0.3s;
}

.control.active, .control:hover {
  background-color: #fff;
  opacity: 1;
  transform: scale(1.2);
}

.content-section {
  height: calc(100% - 150px);
  z-index: 3;


}

.overlay {
  background-color: #151515;
  /*background-color: var(--orange-color);*/
  opacity: 0.15;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.image-design1 {
  margin-top: -200px;
  z-index: 2;
  position: relative;
}

.bg-image {
  padding-top: 5.9em;
  margin-top: -5.4em;
  z-index: 2;
  position: relative;
  background-image: url("../assets/Frame 1.svg");
}

.bc-intro-cover {
  background-image: url("../assets/cover.jpg");
  height: 100%;
  border-radius: 30px 0 30px 30px;
}

.blur-bg-image {
  background-image: url("../assets/cover.jpg");
  height: 500px;
  -webkit-filter: blur(15px);
  z-index: -1;
  position: relative;
  background-position: top!important;
}

.wrp {
  min-height: 505px;
  margin-top: -525px;
  background-color: rgba(0, 30, 60, 0.8);
  z-index: 2;
  position: relative;

}

.bz2 {
  background-color: rgba(252, 127, 3, 0.8);
}

.new-btn-type {
  transform: scale(0.8);
  margin-left: -17px;
  background-color: rgba(0, 30, 60, 0.8);
  border-radius: 5000px;
}

.new-btn-type i {
  color: var(--deep-blue-color);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-left: 20px;
}

.slick-center {
  transform: scale(1.05);
}
.slick-track{
  margin:30px!important;
}

.slickItem{
  height:300px;
  /* width:500px; */
  margin:10px;
  border-radius: 10px;
  box-shadow: 0 0 17px 1px rgba(0, 0, 0, 20%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 0.6s;
}
.wrp-text *{
  color:white!important;
}
.wrp-text::before{
  content: url("../assets/quote.white.svg");

  display: inline-block;
  transform: rotate(180deg);
  top:20px;
  position: relative;
}
.wrp-text > *:first-child{
  text-indent:60px;
  color: rgba(252, 127, 3, 0.8)!important;
  margin-top: 10px;
}
.roboto-inside, .roboto-inside *{
  font-family: 'Roboto', sans-serif!important;
  font-weight: lighter;
}
#abt-the-bc{
  background-image: url('/assets/newphotos/IMG_0031.jpeg');
  background-position:25% 10%;
        /* // '/assets/home-slider/00eb7f11-356f-4ef7-8a0d-6fc9897bc713.JPG', */
        /* '/assets/home-slider/28ce4429-dda6-4569-8a5c-a6efdbdfaf09.JPG', */
        /* // '/assets/home-slider/713096c5-71b9-4050-adf2-594d397c6536.JPG', */
}
</style>
