<template>
  <div>

    <Bizarresvg color="#151515" svgclass="w-100 image-design1 imdff" v-if="!notbizarre"/>
    <div class="bg-image _background" style="background-color: #151515;">
      <div class="container ">
        <div class="row my-1 px-5">
          <div class="col-sm-12 col-md-4 mt-3">
            <h4 class="fw-lighter text-white text-decoration-underline mb-5">Quick Links</h4>
              <ul>
                  <li v-for="page in pages" :key="page.title" class="my-2 fw-lighter"><a :href="page.selected?null:page.location"  :style="{textDecoration:page.selected?'underline':'none',color:'white'}">{{page.title}}</a></li>
              </ul>
          </div>
          <div class="col-sm-12 col-md-4 mt-3">
            <h4  class="fw-lighter text-white text-decoration-underline mb-5">Social links</h4>
            <ul>
              <li class="my-2 fw-lighter"><a href="https://fb.me/OfficialPremierDev"  :style="{color:'white'}">Facebook</a></li>
              <li class="my-2 fw-lighter"><a href="https://twitter.com/premierdev_org"  :style="{color:'white'}">Twitter</a></li>
              <li class="my-2 fw-lighter"><a href="https://www.linkedin.com/company/premierdev/"  :style="{color:'white'}">LinkedIn</a></li>
            
            </ul>
          </div>
          <div class="col-sm-12 col-md-4" v-if="!wContact">
            <h4  class="fw-lighter text-white text-decoration-underline mb-5">Contact PremierDev</h4>
            <ul>
              <li class="my-2 fw-lighter my-4"><i class="fa fa-phone"></i> &nbsp;&nbsp;&nbsp; <a href="tel:447842354900" class="text-white">+44 7 842 354 900</a></li>
              <li class="my-2 fw-lighter my-4"><i class="fa fa-whatsapp-square"></i> &nbsp;&nbsp;&nbsp; <a href="https://wa.me/447842354900" class="text-white">+44 7 842 354 900</a></li>
              <li class="my-2 fw-lighter my-4"><i class="fa fa-envelope"></i> &nbsp;&nbsp;&nbsp; <a href="mailto:enquiries@premierdev.org" class="text-white">enquiries@premierdev.org</a></li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-4 contact-form-wrapper" v-else style="margin-top:-200px;">
             <div style="background-color: var(--orange-color);border-radius: 4px;" class="_shadow p-3">
              <form @submit="submit">
                <h4 class="text-white mt-2"><strong>Contact Us</strong></h4>
               <input class="form-control mt-4" placeholder="Your name here" v-model="name" required/>
               <input class="form-control mt-3" placeholder="Your email here" v-model="email" required type="email"/>
               <textarea class="form-control mt-4" style="resize: none;height: 150px" placeholder="Your message here" v-model="message" required></textarea>
               <div class="mt-4 text-white d-flex flex-column fw-lighter">
                 <span>Email: <span class="text-decoration-underline" style="cursor:pointer" @click="window.location.href='mailto:enquiries@premierdev.org'">enquiries@premierdev.org</span></span>
                 <span>WhatsApp: <span class="text-decoration-underline" style="cursor:pointer" @click="window.location.href='https://wa.me/447842354900'"> +447842354900</span></span>

               </div>
               <div class="mt-4">
                 <p style="color:white;font-weight:bolder" v-if="submitting_contact_success">Message: This is something cool!</p>
                 <p style="color:red;font-weight:bolder" v-if="submitting_contact_error">Message: This is something cool!</p>
                 <button class="float-end btn text-white" style="background-color: #151515" :disabled="submitting_contact">
                   <strong v-if="!submitting_contact">Submit <i class="fa fa-chevron-right mx-3"></i></strong>
                   <strong v-else>Please wait</strong>
                   </button>
<!--                 spacer-->
                 <br/>
                 <br/>
               </div>
               </form>
             </div>
    <br/>
    <br/>
          </div>
        </div>
        <div>
          <div class="w-100" style="height: 1px;background-color: white;opacity: 0.2"/>
          <center class="text-white py-4 fw-lighter">&copy; 2022, All rights reserved &middot; PremierDev</center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bizarresvg from "@/components/Bizarresvg";
import {postAxios} from '../../config/helpers'

export default {
  name: "Footer",
  components: {Bizarresvg},

  mounted(){
    this.window=window;
    const context=this;
    setTimeout(function(){
       window.$('.imdff').css('margin-top',(0-window.$('.imdff').height()+40))
    },100)
    window.onresize = function(){
      context.initImd1Responsive();
    }
  },
  methods:{
    submit(event){
      event.preventDefault();
      // console.log(this.name),
      this.submitting_contact=true;
      this.submitting_contact_error=null;
      this.submitting_contact_success=null;
      const context=this;
      postAxios('contact', {
      name : this.name,
      email : this.email,
      message : this.message
    }, ()=>{
      context.name=null;
      context.email=null;
      context.message=null;
      context.submitting_contact_success="Message Submitted!";
      setTimeout(function(){
        context.submitting_contact_success=null;
      },3000)
    },()=>{
      context.submitting_contact=false;
    },()=>{
      context.submitting_contact_error="Sorry, that didn't work! Try again please";
    })
    },
     initImd1Responsive(){ //to ensure that the responsive of the svg design of slider is okay
     window.$('.imdff').css('margin-top',(0-window.$('.imdff').height()+40))
    },
  },
  props:{
    notbizarre:Boolean,
    wContact:Boolean,
  },data(){
    return {
      submitting_contact:false,
      window:null,
      pages:[{title:"Home",selected:window.location.pathname==="/",location:"/"},{title:"Bootcamp",selected:window.location.pathname==="/summer-bootcamp",location:"/summer-bootcamp"},{title:"Hackathon",selected:window.location.pathname==="/hackathon",location:"/hackathon"},{title:"About",selected:window.location.pathname==="/about",location:"/about"},{title:"News",selected:window.location.pathname==="/news",location:"/news"},{title:"Blog",selected:window.location.pathname.startsWith("/blog"),location:"/blog"}]
    }
  }
}
</script>

<style scoped>

.bg-image {
  padding-top: 5.9em;
  /*margin-top: -5.4em;*/
  z-index: 2;
  position: relative;
  background-image: url("../assets/Frame 1.svg");
}
ul{
margin: 0;
  padding-left: 0;
}
ul li{
  list-style: none;
  color: white;
  margin: 0;
}
input,textarea{
  background-color: transparent;
  border: 1px solid white;
  font-weight: lighter;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white!important;
  opacity: 0.8; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white!important;
}
._shadow{
  box-shadow:  0 2px 3px rgb(0, 0, 0 , 5%)
}
@media only screen and (max-width:768px) {
  .contact-form-wrapper{
    margin-top: 0!important;
  }
}
</style>
