<template>
  <div style="background-color: #f2f2f2">

    <video src="../assets/videos/pxels.mp4" autoplay muted id="vid" loop>
    </video>
    <div class="container" style="z-index: 2;position: relative">
      <div style="" class=" w-100 d-flex justify-content-between align-items-center">
        <div></div>
        <h2 class="text-white m-0 mt-4">{{categorie.categorie}}</h2>
        <div></div>
      </div>
      <hr style="background-color: rgba(255,255,255,0.5);opacity:0.5"/>
    </div>
    <Header/>
    <div class="page-head ">
      <div class="content-section container">
        <div class="row" >
          <div
              class="col-xl-4 col-lg-5 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-start" style="margin-top: 150px"
          >
            <h6 class="fw-bolder text-white" style="margin-top:-2em;font-size: 3em">
              {{categorie.categorie}}
            </h6>
            <!--<p class="text-white fw-light" style="max-width: 400px">
              We envision an Africa where its youths are adequately skilled to manage the continent's challenges and lend their services to the outside world
            </p>-->
            <!--<button class="btn text-white px-4 py-2 mt-2" style="background: var(--orange-color)">Read Now <i
                class="mx-2 fa fa-chevron-right"></i></button>-->
          </div>

        </div>
      </div>
    </div>
    <Bizarresvg color="#f2f2f2" svgclass="w-100 image-design1 imd1"/>
    <div style="margin-top: 1em">
      <div class="container">
        <center><h2 class="fw-lighter text-decoration-underline">News Items</h2></center>
        <br/>
        <div class="row">
          <div class="col-sm-12 col-md-4">
              <blog-item v-for="(i, k) in all.filter((n,j)=>j%3==0)" :key="k" :cats="i.categorie" :image=i.logo :title=i.title :text=i.content :date=i.date></blog-item>
          </div>
          <div class="col-sm-12 col-md-4">
             <blog-item type="paragraph"  title="Good design is making something intelligible and memorable. Great design is making something memorable and meaningful." text="PREMIER DEV"></blog-item>

               <blog-item v-for="(i, k) in all.filter((n,j)=>j%3==2)" :key="k" :cats="i.categorie" :image=i.logo  :title=i.title :text=i.content :date=i.date></blog-item>
          </div>
          <div class="col-sm-12 col-md-4">
           
             <blog-item v-for="(i, k) in all.filter((n,j)=>j%3==1)" :key="k" :cats="i.categorie" :image=i.logo  :title=i.title :text=i.content :date=i.date></blog-item>
           </div>
         
        </div>
      </div>
    </div>
  <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Bizarresvg from "@/components/Bizarresvg";
import BlogItem from "@/components/BlogItem";
import Footer from "@/components/Footer";

import {dataAxios, link} from '../../config/helpers'

export default {
  name:"Blog",
  components:{
    Footer,
    Bizarresvg,
    Header,
    BlogItem
  },
  data(){
    return {
      all: [],
      categorie: ""
    }
  },
  methods:{
  initImd1Responsive(){ //to ensure that the responsive of the svg design of slider is okay
     window.$('.imd1').css('margin-top',(0-window.$('.imd1').height()-20))
    },
  },
  mounted() {
    dataAxios('categories/'+this.$route.params.id, (res)=>{
      console.log(res)
      this.categorie = res.data;
    })

    dataAxios('categories/data/'+this.$route.params.id, (resp)=>{
      console.log(resp.data)
      this.all = Object.values(resp.data).map(all=>{
        return{
          logo: link.replace('api/', 'storage/')+all.logo.split("\\").join("/"),
          title: all.title,
          subtitle: all.subtitle,
          image: all.image,
          categorie: JSON.parse(all.categorie),
          date: all.updated_at.split('T')[0],
        }
      })
    })

    document.getElementById('vid').play();

    const context=this;
    setTimeout(function(){
      window.$('.imd1').css('margin-top',(0-window.$('.imd1').height()-20))
    },100)
    window.onresize = function(){
      context.initImd1Responsive();
    }
    
  }
}
</script>
<style scoped>

.page-head{
  margin-top:-150px;
  /*z-index: 0;*/
  position:relative;
  /*background-image: url("../assets/dummyimage.jpg");*/
  /*background-image: url("../assets/hero-bg.jpg");*/
  /*background-color: #151515;*/
  background-size: cover;
  background-repeat: no-repeat;background-position:center;
  height: 700px;
}
.page-head::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #151515;
  opacity: 0.79;
}
.content-section {
  height: calc(100% - 100px);
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-head::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #151515;
  opacity: 0.79;
}
.blog-header-image >div{
  transition: all ease 0.7s;
  display: flex;
  height: 275px;
  width: 100%;
  background: linear-gradient(transparent,rgba(0,0,0,0.8),  rgba(0,0,0,0.8));

}
.blog-header-image >div:hover{
  background:  rgba(0,0,0,0.85);

  /*opacity: 0.7;*/

  /*background: radial-gradient(transparent,rgba(0,0,0,0.4),  rgba(0,0,0,0.8));*/
}

.blog-header-image.big > div{
  height: 550px;
}
.blog-header-image>div:hover{

}
.thetag{
  position: relative;
  z-index: 2;
}
.image-design1{
  position: relative;
  /* top: -160px; */
}
video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: 600px   !important;
  position: absolute;
  object-fit: cover;
}
</style>
