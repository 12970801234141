<template>
  <div style="background-color:#f2f2f2">
    <video src="../assets/videos/pxels.mp4" autoplay muted id="vid" loop playsinline>
    </video>
   <div class="container" style="z-index: 2;position: relative">
     <div style="" class=" w-100 d-flex justify-content-between align-items-center">
     <div></div>
       <h2 class="text-white m-0 mt-4">Our Blog</h2>
       <div></div>
     </div>
     <hr style="background-color: rgba(255,255,255,0.5);opacity:0.5"/>
   </div>
    <Header/>
    <div class="page-head">
      <div class="container">
        <div class="row " style="z-index: 2;position: relative;top:200px">
          <div class="col-sm-1"></div>
          <div v-if="publications[0]" class="col-sm-11 col-md-5 p-0" style="cursor:pointer" @click="$router.push('/blog/'+publications[0].id)" >
              <div class="blog-header-image big animate__animated animate__fadeInUp" :style="{backgroundImage: `url(${publications[0].logo})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}">
              <div class="d-flex flex-column justify-content-end p-4 align-items-start">
                <div class="thetag btn btn-sm text-white px-3 mb-3" style="background-color: var(--orange-color);border-radius: 0!important" >
                  <a v-for="(cat, index) in publications[0].categorie" :key="index" >{{cat}}{{index===(publications[0].categorie.length-1)?'':', '}}</a>
                </div>
                <h4 class="text-white mb-0">{{truncateString(publications[0].title,64)}}</h4>
                <p class="text-white-50 mt-2">{{publications[0].name}} <strong class="fs-1" style="bottom: -8px;line-height:0;position:relative;">&middot;</strong> {{publications[0].date}}</p>
              </div>
            </div>
          </div>
          <div class="col-sm-11 col-md-5 p-0">


           <div v-if="publications[1]" class="blog-header-image  animate__animated animate__fadeInUp" style="cursor:pointer" @click="$router.push('/blog/'+publications[1].id)" >
              <div class="d-flex flex-column justify-content-end p-4 align-items-start ">
                <div class="thetag btn btn-sm text-white px-3 mb-3" style="background-color: var(--orange-color);border-radius: 0!important" >
                   <a v-for="(cat, index) in publications[1].categorie" :key="index" >{{cat}}{{index===(publications[1].categorie.length-1)?'':', '}}</a>
                </div>
                <h4 class="text-white mb-0">{{truncateString(publications[1].title,64)}}</h4>
                <p class="text-white-50 mt-2">{{publications[1].name}} <strong class="fs-1" style="bottom: -8px;line-height:0;position:relative;">&middot;</strong> {{publications[1].date}}</p>
              </div>
            </div> 


            <div v-if="publications[2]" class="blog-header-image  animate__animated animate__fadeInUp" style="cursor:pointer" @click="$router.push('/blog/'+publications[2].id)" >
              <div class="d-flex flex-column justify-content-end p-4 align-items-start">
                <div class="thetag btn btn-sm text-white px-3 mb-3" style="background-color: var(--orange-color);border-radius: 0!important" >
                    <a v-for="(cat, index) in publications[2].categorie" :key="index" >{{cat}}{{index===(publications[2].categorie.length-1)?'':', '}}</a>
                </div>
                <h4 class="text-white mb-0">{{truncateString(publications[2].title,64)}}</h4>
                <p class="text-white-50 mt-2">{{publications[2].name}} <strong class="fs-1" style="bottom: -8px;line-height:0;position:relative;">&middot;</strong> {{publications[2].date}}</p>
              </div>
            </div>
          </div> 
          <div class="col-sm-1"></div>
        </div>
      </div>
    </div>
<!--    <img src="../assets/download 1_place_1.svg" class="w-100 image-design1"/>-->
    <Bizarresvg color="#f2f2f2" svgclass="w-100 image-design1 imd1"/>
    <div style="background-color:#f2f2f2;" class="other-articles">
      <br/>
      <div class="container">
        <center><h2 class="fw-lighter text-decoration-underline">Other Articles</h2></center>
        <div class="row">
          <div class="col-sm-12 col-md-4">
              <blog-item v-for="(pub, i) in pub_short.filter((p,id)=>(id%3==0))"  :pubId=pub.id :image=pub.logo :key="i" :cats=pub.categorie :title=pub.title :text=pub.summary></blog-item>
          </div>
          <div class="col-sm-12 col-md-4">
            <blog-item type="paragraph"  title="Good design is making something intelligible and memorable. Great design is making something memorable and meaningful." text="PREMIER DEV"></blog-item>
              <blog-item v-for="(pub, i) in pub_short.filter((p,id)=>(id%3==2))"  :pubId=pub.id :image=pub.logo :key="i" :cats=pub.categorie :title=pub.title :text=pub.summary></blog-item>
         </div>
          <div class="col-sm-12 col-md-4">
          
              <blog-item v-for="(pub, i) in pub_short.filter((p,id)=>(id%3==1))"  :pubId=pub.id :image=pub.logo :key="i" :cats=pub.categorie :title=pub.title :text=pub.summary></blog-item>
         
          </div>
        </div>
      </div>
    </div>

    <br/>
    <posts-footer></posts-footer>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Bizarresvg from "@/components/Bizarresvg";
import BlogItem from "@/components/BlogItem";
import PostsFooter from "@/components/PostsFooter";
import Footer from "@/components/Footer";

import {dataAxios, link, getCurrentUserId} from "../../config/helpers"

export default {
  name:"Blog",
  components:{
    Footer,
    PostsFooter,
    Bizarresvg,
    Header,
    BlogItem
  },
  data(){
    return{
      publications: [],
      pub_short:[]
    }
  },
  mounted() {
    console.log(getCurrentUserId())
    dataAxios('publications', (res)=>{
      const data = res.data.map(pub=>{
        return{
          logo: link.replace('api/', 'storage/')+pub.logo.split("\\").join("/"),
          name: pub.name,
          title: pub.title,
          subtitle: pub.subtitle,
          summary: pub.summary,
          date: new Date(pub.created_at).toDateString(),
          categorie: JSON.parse(pub.categorie),
          id:pub.id
        }
      })
      this.publications =data;
      this.pub_short = data.splice(3);
    })
    document.getElementById('vid').play();
    const context=this;
    context.initImd1Responsive();
     window.onresize = function(){
        context.initImd1Responsive();
      }
  },
  methods:{
    initImd1Responsive(){ //to ensure that the responsive of the svg design of slider is okay
     window.$('.imd1').css('margin-top',(0-window.$('.imd1').height()+200))
    },
    truncateString(str, num) {
      if(!str){
        return '';
      }
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
  }
}
</script>
<style scoped>
body{
  /*background: #19191b;*/
}
.page-head{
  margin-top:-150px;
  /*z-index: 0;*/
  position:relative;
  /*background-image: url("../assets/dummyimage.jpg");*/
  /*background-image: url("../assets/hero-bg.jpg");*/
  /*background-color: #151515;*/
  background-size: cover;
  background-repeat: no-repeat;background-position:center;
  height: 600px;
}
.blog-header-image{
  /*background-image: url("../assets/dummyimage.jpg");*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.blog-header-image:last-child{

  background-image: url("https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80");
}
.page-head::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #151515;
  opacity: 0.79;
}
.blog-header-image >div{
  transition: all ease 0.7s;
  display: flex;
  height: 275px;
  width: 100%;
  background: linear-gradient(transparent,rgba(0,0,0,0.8),  rgba(0,0,0,0.8));

}
.blog-header-image >div:hover{
  background:  rgba(0,0,0,0.85);

  /*opacity: 0.7;*/

  /*background: radial-gradient(transparent,rgba(0,0,0,0.4),  rgba(0,0,0,0.8));*/
}

.blog-header-image.big > div{
  height: 550px;
}
.blog-header-image>div:hover{

}
.thetag{
  position: relative;
  z-index: 2;
}
.image-design1{
  position: relative;
  top: -160px;
}
video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: 600px   !important;
  position: absolute;
  object-fit: cover;
}
@media only screen and (max-width:767px){
  .other-articles{
    margin-top:550px;
  }
}
</style>
