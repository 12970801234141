import axios from 'axios'

export const link = window.location.origin.includes("premierdev")?"https://api.premierdev.org/api/":'https://api.premierdev.org/api/' //http://localhost:8000/api/

export const dataAxios=(endpoint, success, completed, error)=>{
    global.MainAppComponent.loadingPage=true;
    axios.get(link+endpoint)
    .then(res=>{
        if(success){
            success(res.data)
        }
    })
    .catch(err=>{
        global.MainAppComponenterrorLoadingPage=true;
        console.error(err)
        if(error){
            error(err)
        }
    })
    .finally(fin=>{
        setTimeout(function(){
            global.MainAppComponent.loadingPage=false;
        },1000)
        if(completed){
            completed(fin)
        }
    })
}

export const postAxios=(endpoint, data, success, completed, error)=>{
    axios.post(link+endpoint, data)
    .then(res=>{
        if(success){
            success(res.data)
        }
    })
    .catch(err=>{
        console.error(err)
        if(error){
            error(err)
        }
    })
    .finally(fin=>{
        if(completed){
            completed(fin)
        }
    })
}
export const getCurrentUserId=()=>{
    let viewer =window.localStorage.getItem("vId");
    if(!viewer){
        viewer = Math.random()+"_"+Math.random();
        window.localStorage.setItem("vId",viewer)
    }
    return viewer;
}