<template>

  <div class="home">
     <vue-modaltor
            :visible="openModal"  @hideModal="openModal=false">
            <template #body >
                <div class="container pb-5" style="padding:0 40px;" >
                    <img src="/pdicon.png" height="100"/>
                    <h2 style="color:var(--orange-color)">Team Biography</h2>
                    <div style="background-color:var(--orange-color);height:6px;width:90px;"/>
                  
                    <div class="row mt-5" v-for="(t,i) in theteam" :key="'the_t'+i">
                      <div class="col-sm-12 col-md-4 ">
                      <div class=" _background" :style="{backgroundImage:`url('${t.image}')`,backgroundAttachment:'unset'}" style="height:300px;background-position:top!important" >

                      </div>
                      </div>
                      <div class="col-sm-12 col-md-8 ">
                        <h3 class=" "><strong>{{t.name}}</strong></h3>

                    <div style="background-color:var(--orange-color);height:6px;width:90px;margin-top:-3px;margin-bottom:20px;"/>
                          <p class="roboto-inside" v-html="t.bio"></p>
                   
                      </div>
                     </div>

                  
                </div>
            </template>
  </vue-modaltor>
    <video src="../assets/videos/pxels.mp4" autoplay muted id="vid" loop playsinline>
    </video>
    <div class="container" style="z-index: 2;position: relative">
      <div style="" class=" w-100 d-flex justify-content-between align-items-center">
        <div></div>
        <h2 class="text-white m-0 mt-4">About PremierDev</h2>
        <div></div>
      </div>
      <hr style="background-color: rgba(255,255,255,0.5);opacity:0.5"/>
    </div>
    <Header/>
    <div class="page-head ">
      <div class="content-section container">
        <div class="row" >
          <div
              class="col-xl-4 col-lg-5 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-start" style="margin-top: 150px"
              >
            <h6 class="fw-bolder text-white" style="margin-top:-2em;font-size: 3em">
              Our vision
            </h6>
            <p class="text-white fw-light" style="max-width: 400px">
              We envision an Africa where its youths are adequately skilled to manage the continent's challenges and lend their services to the outside world
            </p>
            <a class="btn text-white px-4 py-2 mt-2" href="#startabouthere" style="background: var(--orange-color)">Find Out More <i
                class="mx-2 fa fa-chevron-right"></i></a>
          </div>

        </div>
      </div>s
    </div>
    <!--    <img src="../assets/download 1_place_1.svg" class="w-100 image-design1"/>-->
    <Bizarresvg color="#fff" svgclass="w-100 image-design1 imd1"/>
    <div class="_background bg-image" style="margin-top: -100px;z-index: 2;position: relative;">
      <div class=" fw-lighter  " >
        <div  id="startabouthere">
          <div class="container">
           <h4><strong style="color: var(--deep-blue-color)">Meet the devoted Team</strong></h4>
            <div style="width: 10%;height: 3px;background-color: var(--orange-color)" class="mb-2"></div>
            <br/> 
          </div>
           
          <div class="slickers_team mt-sm-5 mt-md-0" style="padding-top:20px;">

              <div class="slickTeamItem" v-for="(slider,i) in theteam" :key="'theteam_'+i" :style="{backgroundImage: `url(${slider.image})`}">
                <div class="p-4 flex-column justify-content-end align-items-start">
                  <h4>{{slider.name}}</h4>
                  <p v-html="slider.desc" style="max-width:70%" ></p>
<!--                  <h4 class="p-2 text-white btn " style="background: var(&#45;&#45;deep-blue-color);border-radius: 0">{{slider.name}}</h4>-->
                </div>
              </div>
            </div>
         <center><br/><button class="btn text-white px-4 py-2 mt-2" style="background: var(--orange-color)" @click="openModal=true;">View Bios<i
                class="mx-2 fa fa-chevron-right"></i></button></center>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    <div class="bg-white">
      
      <!--      -->
      <!--      <img src="../assets/download 1_place_1.svg" class="w-100 image-design1" style="margin-top: -15em!important;"/>-->

      <!--      <Bizarresvg color="#fff" svgclass="w-100 mt-1 bz2"/>-->
      <!--      <div class="bg-white p-3" style="z-index: 2;position:relative;margin-top: -50px;background-color:rgba(252,127,3,0.8);" >-->
      <!--        <h2>This is it</h2>-->
      <!--      </div>-->

      <div class="bg-white" style="z-index: 2;position: relative;height: 50px;">
        <!--        <h1>sd</h1>-->
      </div>
      <div class="_background bg-image" style="background-color: #fff">
        <div class="container fw-lighter  " style="max-width: 960px">
          <div class="row">
  <div class="col-sm-12"> 
    <!--  class="col-sm-12 col-md-5 col-sm-7 px-3 d-flex flex-column justify-content-center align-items-start" -->
              <!-- <h6><strong>ABOUT PREMIERDEV</strong></h6> -->
              <h4><strong style="color: var(--orange-color)">We abide to the following values </strong></h4><br/>
              <p class="mt-3" style="padding-right: 40px">The approaches we use are simple, professional, basic and
                efficient.We do our very best possible to ensure maximum comprehension, maximum interpretation and hence
                maximum results </p>
              <!-- <button class="btn text-white px-4 py-2 mt-2" style="background: var(--orange-color)">Find Out More <i
                  class="mx-2 fa fa-chevron-right"></i></button> -->
            </div>
            <div class="col-sm-12 col-md-7 col-sm-7 px-2">
              <div class="row"><div class=" col-sm-6">

                 <div v-if="(weabideby.length%2==0)">
                      <br/>
                      <br/>
                      <br/>
                  </div>
                <div v-for="(wab,index) in weabideby.filter((w,b)=>b%2==0)" :key="wab" :style="{backgroundColor: index===1? 'var(--orange-color)':'white'}" :class="index===0?'shadow  p-4':'shadow  p-4 mt-4'" style="border-radius: 5px;">
                  <i :class="wab.fa_icon +' fs-1 mb-3'"  :style="{color: index!==1? 'var(--orange-color)':'white'}"></i>
                  <br/>
                  <strong style="color: var(--deep-blue-color);margin-top: 20px;">{{wab.values.name}}</strong>
                  <p class="text-black-50">{{(wab.values.description==wab.values.name)?'':wab.values.description}}</p>
<!--                  <button class="text-white btn px-4 new-btn-type" style="">Apply Now <i
                      class="fa fa-chevron-right p-2 bg-white"></i></button>-->
                </div>
              </div>
                <div class=" col-sm-6">
                 <div v-if="(weabideby.length%2==1)">
                      <br/>
                      <br/>
                      <br/>
                  </div>
                  <div v-for="(wab,index) in weabideby.filter((w,b)=>b%2==1)" :key="wab" :style="{backgroundColor: index===2? 'var(--orange-color)':'white'}" :class="index===0?'shadow  p-4':'shadow  p-4 mt-4'" style="border-radius: 5px;">
                    <i :class="wab.fa_icon +' fs-1 mb-3'"  :style="{color: index!==2? 'var(--deep-blue-color)':'white'}"></i>
                    <br/>
                    <strong style="color: var(--deep-blue-color);margin-top: 20px;">{{wab.values.name}}</strong>
                            <p class="text-black-50">{{(wab.values.description==wab.values.name)?'':wab.values.description}}</p>
<!--                    <button class="text-white btn px-4 new-btn-type" style="">Apply Now <i
                        class="fa fa-chevron-right p-2 bg-white"></i></button>-->
                  </div>


                </div>
              </div>
            </div>

          
          </div>
        </div>
      
      </div>
    </div>
    <br/>
    <br/>
    <br/>
    <Footer :notbizarre="true"/>
  </div>
</template>

<script>

import Header from "@/components/Header";
import Bizarresvg from "@/components/Bizarresvg";
import Footer from "@/components/Footer";

import {dataAxios, link} from '../../config/helpers';

export default {
  name: 'Home',
  components: {
    Footer,
    Bizarresvg,
    Header
  },
  data() {
    return {
      openModal:false,
      sliders: [
        'https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80', 'https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80', 'https://images.unsplash.com/photo-1504707748692-419802cf939d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1330&q=80',
        'https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80', 'https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80', 'https://images.unsplash.com/photo-1504707748692-419802cf939d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1330&q=80',
      ],
      studenlist: [],
      theteam: [],
      weabideby:[],
      // weabideby:[
      //   "DYNAMISM",
      //   "TEAMWORK",
      //   "COMMITMENT",
      //   "LIFELONG LEARNING",
      //   "OBSESSION FOR QUALITY RESULTS"
      // ].map(r=>({name:r,description:r})),
      faqs: [],
      slickObject: null,
      studen_pos:0,
      current_faq_answer:null

    }
  },
  mounted() {   
    const context=this;
    dataAxios('teams', (res)=>{
      context.theteam = res.data.map(entry=>{
        return{
          name:entry.name,
          desc:entry.description,
          image:link.replace('api/', 'storage/')+entry.image.split("\\").join("/"),
          bio:entry.bio
        }
      })
      
      dataAxios('student', (res)=>{
        context.studenlist = res.data.map(student=>{
          return{
            name:student.name,
            text:student.description,
            image:link.replace('api/', 'storage/')+student.image.split("\\").join("/")
          } 
        })
        setTimeout(function(){
          context.initSlick()
        }, 500);
 dataAxios('values', (res)=>{
            context.weabideby = res.data.map(values=>{
              console.log(values);
              return{
                values,
                fa_icon:values.fa_icon
              }
            })
            console.log(res)
          })
        
      })
    });

    this.initImd1Responsive();
 
    window.onresize = function(){
      context.initImd1Responsive();
    }
  },
  methods: {
      initImd1Responsive(){ //to ensure that the responsive of the svg design of slider is okay
        window.$('.imd1').css('margin-top',(0-window.$('.imd1').height()-20))
        window.$('.imd2').css('margin-top',(0-window.$('.imd2').height()-20))
    },
    initSlick(){
      let context=this;
      this.slickObject = window.$('.slickers_bootcamp').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '90px',
        focusOnSelect: true,
        autoplay:true,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // infinite: true,
              // dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });
      window.$('.slickers_team').slick({
        dots: false,
        infinite: true,
        // speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        // centerPadding: '40px',
        focusOnSelect: true,
        // autoplay:true,
        // autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3.5,
              slidesToScroll: 1,
              // infinite: true,
              // dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1.15,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 0.99,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });
      window.$('.slickers_bootcamp').on('afterChange', function(x,y,z){
        context.studen_pos =z;
      });
    }
  }
}
</script>
<style scoped>

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}
.page-head{
  margin-top:-150px;
  /*z-index: 0;*/
  position:relative;
  /*background-image: url("../assets/dummyimage.jpg");*/
  /*background-image: url("../assets/hero-bg.jpg");*/
  /*background-color: #151515;*/
  background-size: cover;
  background-repeat: no-repeat;background-position:center;
  height: 700px;
}
.page-head::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #151515;
  opacity: 0.79;
}
.content-section {
  height: calc(100% - 100px);
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay {
  background-color: var(--orange-color);
  opacity: 0.15;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.image-design1 {
  /* margin-top: -200px; */
  z-index: 0;
  position: relative;
}

.bg-image {
  padding-top: 5.9em;
  margin-top: -5.4em;
  z-index: 2;
  position: relative;
  background-image: url("../assets/Frame 1.svg");
}

.bc-intro-cover {
  background-image: url("../assets/cover.jpg");
  height: 100%;
  border-radius: 30px 0 30px 30px;
}

.blur-bg-image {
  background-image: url("../assets/cover.jpg");
  height: 520px;
  -webkit-filter: blur(3px);
  z-index: -1;
  position: relative;
}

.wrp {
  min-height: 525px;
  margin-top: -520px;
  background-color: rgba(0, 30, 60, 0.8);
  z-index: 2;
  position: relative;

}

.bz2 {
  background-color: rgba(252, 127, 3, 0.8);
}

.new-btn-type {
  transform: scale(0.8);
  margin-left: -17px;
  background-color: rgba(0, 30, 60, 0.8);
  border-radius: 5000px;
}

.new-btn-type i {
  color: var(--deep-blue-color);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-left: 20px;
}

.slick-track{
  margin:30px!important;
}

.slickItem{
  height:400px;
  /* width:200px; */
  margin:10px;
  border-radius: 10px;
  box-shadow: 0 0 17px 1px rgba(0, 0, 0, 20%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 0.6s;
}
.slickTeamItem{
  height:450px;
  width:200px;
  margin:15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 0.6s;
    border-radius: 0 25px;
    box-shadow: 0 0 7px 5px rgb(0, 0, 0 , 20%)
}
.slickTeamItem >div{
    border-radius: 0 25px;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 30, 60, 0.6);
  transition: all ease 0.6s;
  /* opacity: 0; */
  justify-content: center;
  align-items: center;

}
.slickTeamItem > div *{
  color:white;
}
.slickTeamItem:hover > div{
  opacity: 1;
}

.slick-prev::before, .slick-next::before{
  background-color: red!important;
}
.slickers_team button::before{
    color: var(--deep-blue-color) !important;
}
.slickItem >div{
  border-radius: 10px;
  background-color: rgba(252, 127, 3, 0.2);
  height: 100%;
  width: 100%;
}
.slickItem >div *{
  opacity: 0;
  transition: all ease 0.6s;
}

.slickers_bootcamp .slick-center {
  background-position: top;
  transform: scale(1.05);
}
.slickers_bootcamp .slick-center >div *{
  opacity: 1;
}
.wrp-text *{
  color:white!important;

}
.wrp-text::before{
  content: url("../assets/quote.white.svg");

  display: inline-block;
  transform: rotate(180deg);
  top:20px;
  position: relative;
}
.wrp-text > *:first-child{
  text-indent:60px;
  color: rgba(252, 127, 3, 0.8)!important;
  margin-top: 10px;

}
video {
  /* override other styles to make responsive */
  width: 100%    !important;
   height: 680px   !important;
  position: absolute;
  object-fit: cover;
}

.stdents-remark{
  background-image: url('/assets/home-slider/people.jpeg')!important;
}
</style>
