import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css';
import 'animate.css/animate.min.css';
import './assets/css/general-responsive.css';
import VueModalTor from "vue-modaltor/dist/vue-modaltor.common";
import "vue-modaltor/dist/vue-modaltor.css"
Vue.config.productionTip = false

Vue.use(VueModalTor, {
  bgPanel: "#fff",  // add custome options
  bgOverlay:"rgba(0,0,0,0.5)"
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
