<template>
  <svg :width="width" :height="height" viewBox="0 0 1440 181" fill="none" xmlns="http://www.w3.org/2000/svg" :style="svgstyle" :class="svgclass">
    <g clip-path="url(#clip0_3_4)">
      <path d="M1440 182.476V175.327C1440 153.287 1426.51 134.371 1410.03 133.293L29.9677 43.0372C13.4858 41.9594 0 23.4953 0 2.00523V182.476H1440Z" :fill="color?color:'white'"/>
    </g>
    <defs>
      <clipPath id="clip0_3_4">
        <rect width="1440" height="181" :fill="color?color:'white'"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "Bizarresvg",
  props:{
    width:Number,
    height:Number,
    color:String,
    svgclass:String,
    svgstyle:String
  }
}
</script>

<style scoped>

</style>
