<template>

  <div class="justify-content-center align-items-center container d-flex text-white menu mt-3"
       style="z-index: 8;position: relative" id="main-menu-wrapper">
    <span  v-for="page in pages" :key="page.title" class="d-none d-md-block" style="cursor: pointer"
          :style="{textAlign:'center',fontWeight:page.selected?'bolder':'lighter',textDecoration:page.selected?'underline':'none'}"
          @click="openLink(page.loc)">{{ page.title }}</span>
   <div id="menu-consstroller-on-mobile" class=" d-flex d-md-none float-end w-100 justify-content-start" >
      <span @click="openSmallMenu" class="pt-5" style="margin-top:-44px;right:5%;position:relative;">
      <i class=" fa fa-bars fa-2x"></i>
    </span> 
   </div>
  </div>

</template>

<script>
export default {
  name: "Header",
  mounted(){
   
    // setTimeout(function(){
    //    window.openSmallMenu= function(){
    //         global.MainAppComponent.openMenu=!global.MainAppComponent.openMenu;
    //     }  
    // },1000)
    // document.getElementById("menu-consstroller-on-mobile").addEventListener("click", function() {
    //       window.openSmallMenu();
    //   });
    this.initSlick();
    window.openSmallMenu = this.openSmallMenu;
   
  },
  components:{
    
  },
  methods:{
    openLink(link){
      if(link=="/"){
        window.location.href="/";
      }else{
         this.$router.push(link)
      }
    },
    openSmallMenu(){
      // global.MainAppComponent.set('openMenu',global.MainAppComponent.openMenu);
      setTimeout(function(){
      global.MainAppComponent.openMenu=!global.MainAppComponent.openMenu;
          // global.MainAppComponent.$forceUpdate();
        //   setTimeout(function(){
        // global.MainAppComponent.openMenu=!global.MainAppComponent.openMenu;
        //     global.MainAppComponent.$forceUpdate();
        // },0)
      },300)
      //  this.$router.go();
        // alert();
      //  this.s=Math.random();
    },
    initSlick() {
       
      //  this.slickObject = window.$('#main-menu-wrapper').slick({
      //   dots: false,
      //   infinite: false,
      //   speed: 300,
      //   slidesToShow: 2.5,
      //   slidesToScroll: 2,
      //   centerMode: true,
      //   // centerPadding: '60px',
      //   // focusOnSelect: true,
      //   mobileFirst: true, //beakpoints considered differently from top to bottom
      //   responsive: [
      //     {
      //       breakpoint: 1024,
      //       settings: "unslick"
      //     },
      //     {
      //       breakpoint: 600,
      //       settings: {
      //         slidesToShow: 3.5,
      //         slidesToScroll: 2
      //       }
      //     },
      //     {
      //       breakpoint: 480,
      //       settings: {
      //         slidesToShow: 3.5,
      //         slidesToScroll: 2
      //       }
      //     }
      //     // You can unslick at a given breakpoint now by adding:
      //     // settings: "unslick"
      //     // instead of a settings object
      //   ]
      // });


      }
  },
  data() {
    return {
      pages: [
        {title: "Home", selected: window.location.pathname === "/", loc: "/"}, {
        title: "About",
        loc: "/about",
        selected: window.location.pathname === "/about"
      }, {title: "Hackathon", loc: "/hackathon", selected: window.location.pathname === "/hackathon"},
        {
        title: "Bootcamp",
        loc: "/summer-bootcamp",
        selected: window.location.pathname === "/summer-bootcamp"
      },
       
       
       {title: "News", loc: "/news", selected: window.location.pathname === "/news"}, 
       {
        title: "Blog",
        loc: "/blog",
        selected: window.location.pathname.startsWith("/blog")
      }]
    }
  }
}
</script>

<style scoped>
.menu span {
  margin: 10px 15px;
  font-weight: lighter;
}
</style>
