<template>
  <svg :width="width" :height="height" viewBox="0 0 1440 181" :fill="color" xmlns="http://www.w3.org/2000/svg" :style="svgstyle" :class="svgclass">
    <g clip-path="url(#clip0_17_45)">
      <path d="M0 0V7.16869C0 29.2743 13.4951 48.2458 29.9892 49.3266L1411.01 139.847C1427.51 140.928 1441 159.447 1441 181V0H0Z" :fill="color"/>
    </g>
    <defs>
      <clipPath id="clip0_17_45">
        <rect  width="1440" height="181" :fill="color"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Bizarresvg",
  props:{
    width:Number,
    height:Number,
    color:String,
    svgclass:String,
    svgstyle:String
  }
}
</script>

<style scoped>

</style>
