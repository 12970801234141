<template>
  <div>
<!--    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <div class="main-loader" id="pd-main-loading-component" v-if="loadingPage||errorLoadingPage">
        <LetterCube style="transform:scale(2)" v-if="loadingPage" ></LetterCube>
        <div  v-if="errorLoadingPage">
           <center>
              <i class="fa fa-3x fa-network-wired" style="color:#fc7f03"></i>
               <p class="mt-2">Sorry, that didnt work! <br/>Please check your network and try again!</p>
           </center>
           
        </div>
        <!-- <p class="mt-4" style="color:var(--orange-color)">Please wait...</p> -->
    </div>
    <Slide right :isOpen="openMenu">
      <a v-for="p in pages" :id="'menu_'+p.title" :href="p.loc" :key="p.loc"> 
        <span>{{p.title}}</span>  
      </a>
    </Slide>
    <router-view/>
  </div>
</template>
<script>

import {LetterCube} from 'vue-loading-spinner'
import { Slide } from 'vue-burger-menu' 

export default {
  components:{
    LetterCube,
    Slide
  },
  created(){
    global.MainAppComponent=this;
    window.AppVue=this;
  },
  mounted() {
    // window.onresize = function(){
    //     if(window.locaiton.href.includes("summer-bootcamp")){
    //       var $ds = window.$('.image-design1');
    //       for(var i =0;i<$ds.length;i++){
    //         window.$($ds[i]).css('margin-top',(0-window.$($ds[i]).height()))
    //       }
    //     }else{
    //       $ds = window.$('.image-design1');
    //       for( i =0;i<$ds.length;i++){
    //         window.$($ds[i]).css('margin-top',(0-window.$($ds[i]).height()-200))
    //       }
    //     }
    //   }
  },
  data(){
    return {
      loadingPage:false,
      errorLoadingPage:false,
      openMenu:false,
      sx:null,
      pages:[
        {title: "Home", selected: window.location.pathname === "/", loc: "/"}, {
        title: "About",
        loc: "/about",
        selected: window.location.pathname === "/about"
      }, {title: "Hackathon", loc: "/hackathon", selected: window.location.pathname === "/hackathon"},
        {
        title: "Bootcamp",
        loc: "/summer-bootcamp",
        selected: window.location.pathname === "/summer-bootcamp"
      },
       
       
       {title: "News", loc: "/news", selected: window.location.pathname === "/news"}, 
       {
        title: "Blog",
        loc: "/blog",
        selected: window.location.pathname.startsWith("/blog")
      }]
    }
  }
}
</script>


<style>
.main-loader{
  display: flex;
  height:100vh;
  width: 100vw;
  background-color: rgba(255,255,255,0.7);
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index: 999;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cube.panelLoad .cube-face {
    font-family: Open Sans,sans-serif!important;
    font-size: 50px!important;
    color: #FC7F03!important;
    box-shadow: inset 0 0 0 1px #fc7f03, 0 0 1px 1px #fc7f03!important;
}
</style>
