<template>
<div class="posts-footer-wrapper">
  <div class="p-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-8 col-xl-7">
              <h3><strong>Popular Posts</strong></h3>
              <div class="row">
                <div class="col-sm-12 col-md-6" v-for="(pop, i) in populars" :key="i">
                    <div class="d-flex align-items-center post-item-wrapper justify-content-between p-2 py-3">
                       <div class="mx-2" :style="{backgroundImage: `url(${pop.logo})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '75px !important', 'minWidth': '100px !important'}"></div>
                      <div class="flex-grow-1">
                        <h6 class="m-0"><strong>{{truncateString(pop.title,70)}}</strong></h6>
                        <p class="m-0 text-black-50">{{pop.date}}</p>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          <div class="col-sm-12 col-md-4 col-xl-5">
           <div class="container">
             <h3><strong>Social Media</strong></h3>
             <!-- <VueFacebookPage data-href="https://www.facebook.com/OfficialPremierDev" link-text="Premier Dev" /> -->
    
             <div class="fb-page" data-href="https://www.facebook.com/OfficialPremierDev" data-tabs="timeline" data-width="" data-height="300" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/OfficialPremierDev" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/OfficialPremierDev">PremierDev</a></blockquote></div>
             <!-- <p class="fw-light">
               My objectif is to put a twitter/facebook social media feed here through the API.
               Donec sollicitudin molestie malesuada. Nulla quis lorem ut libero malesuada feugiat. Pellentesque in ipsum id orci porta dapibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Donec sollicitudin molestie malesuada.
             </p> -->
           </div>
          </div>
        </div>

        <div class="container mb-5" style="max-width: 960px;">
          <br/>
          <br/>
          <center><h3 class="mb-4"><strong>Categories</strong></h3></center>
          <div class="d-flex justify-content-center align-items-center flex-wrap">
            <div class="btn cat-tag" v-for="(cat, i) in categories" :categorie=cat.categorie :key="i" @click="$router.push('/cat/'+cat.id)">{{cat.categorie}}</div>
          </div>
        </div>

      </div>
  </div>
</div>
</template>

<script>
import {dataAxios, link} from "../../config/helpers"
import { loadScript } from "vue-plugin-load-script";

export default {
  name: "PostsFooter",
  components:{
    
  },
  data(){
    return{
      categories: [],
      populars: []
    }
  },
  methods:{
    truncateString(str, num) {
      if(!str){
        return '';
      }
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    }
  },
  mounted(){
    loadScript(
      "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v13.0&appId=524182434617754&autoLogAppEvents=1"
    )
      .then(() => {
        this.$nextTick(() => {
          window.FB.XFBML.parse();
        });
      })
      .catch(() => {
        console.log("SCRIPT LAODING ERROR");
      });
    dataAxios('popular', (res)=>{
      this.populars = Object.values(res.data).map(pub=>{
        return{
          logo: link.replace('api/', 'storage/')+pub.logo.split("\\").join("/"),
          name: pub.name,
          title: pub.title,
          date: new Date(pub.created_at).toDateString()
        }
      })
      console.log(this.populars)
    
    }),
    dataAxios('categories', (res)=>{
      console.log(res)
      this.categories = res.data.map(pub=>{
        return{
          id : pub.id,
          categorie : pub.categorie
        }
      })
    console.log(this.categories)
    })
  }
}
</script>

<style scoped>
.posts-footer-wrapper{
  /*background-image: url("../assets/dummyimage.jpg");*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}
.posts-footer-wrapper>div{
  background-color:rgba(255,255,255,0.98);
}
.post-item-wrapper >div:first-child{
  /*background-image: url("../assets/dummyimage.jpg");*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 75px!important;
  width: 100px!important;
}
.cat-tag{
  background-color: #8a8a8a;
  z-index: 10;
  color:white!important;
  margin:10px;
 transition: all ease 0.8s;
 cursor: pointer !important;
}
.cat-tag:hover{
  background-color: var(--deep-blue-color);
}
</style>
