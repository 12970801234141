import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Blog from '../views/Blog.vue'
import BlogInside from "@/views/BlogInside";
import BootCamp from "@/views/BootCamp";
import About from "@/views/About";
import News from "@/views/News";
import Hackathon from "@/views/Hackathon";
import BlogCategories from "@/views/BlogCategories";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/blog/:id',
    name: 'BlogInside',
    component: BlogInside
  },
  {
    path: '/cat/:id',
    name: 'BlogCategory',
    component: BlogCategories
  },
  {
    path: '/summer-bootcamp',
    name: 'SummerBootCamp',
    component: BootCamp
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/hackathon',
    name: 'Hackathon',
    component: Hackathon
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
