<template>
  <div class="d-flex my-4 w-100">
      <div style="width: 80px">
          <img height="70" width="70" :src="gravatarUrl" class="avatar" style="border-radius: 50%"/>
      </div>
    <div class="d-flex flex-column mx-4">
        <strong>{{name}}</strong>
      <small class="text-black-50 fw-light">{{date}}</small>
      <p class="fw-lighter text-black-50 mt-2">
        {{comment}}
      </p>

    </div>
  </div>
</template>
<script>
export default {
  props:{
    email:String,
    name:String,
    date:String,
    comment:String
  },
  data(){
    return {
      gravatarUrl:""
    }
  },
  mounted() {
     this.gravatarUrl= "https://www.gravatar.com/avatar/"+window.CryptoJS.MD5((this.email?this.email:"anytext").trim().toLowerCase()).toString();
  }
}
</script>
<style scoped>
 .avatar{
   height: 50px;
   width: 50px;
   border-radius: 50%;
 }
</style>
