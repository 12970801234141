<template>
  <div class="home">

    <!-- <video src="../assets/videos/pxels.mp4" autoplay muted id="vid" loop playsinline> -->
    <div id="vid"></div>
    <!-- </video> -->
    <div class="container" style="z-index: 2;position: relative">
      <div style="" class=" w-100 d-flex justify-content-between align-items-center">
        <div></div>
        <h2 class="text-white m-0 mt-4">PremierDev Hackathon</h2>
        <div></div>
      </div>
      <hr style="background-color: rgba(255,255,255,0.5);opacity:0.5"/>
    </div>
    <Header/>
    <div class="page-head ">
      <div class="content-section container">
        <div class="row" >
          <div
              class="col-xl-4 col-lg-5 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-start" style="margin-top: 150px"
              >
            <h6 class="fw-bolder text-white" style="margin-top:-2em;font-size: 3em">
              The Hackathon
            </h6>
            <p class="text-white fw-light" style="max-width: 400px">
              We envision an Africa where its youths are adequately skilled to manage the continent's challenges and lend their services to the outside world
            </p>
            <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSecc_RST3uaQ-n85vBa1zoP805d0NYIAlvpAiME8b3aesyVDA/viewform" class="btn text-white px-4 py-2 mt-2" style="background: var(--orange-color)">Apply Now <i
                class="mx-2 fa fa-chevron-right"></i></a>
          </div>

        </div>
      </div>s
    </div>
    <!--    <img src="../assets/download 1_place_1.svg" class="w-100 image-design1"/>-->
    <Bizarresvg color="#fff" svgclass="w-100 image-design1 imd1"/>
    <div class="_background bg-image" style="margin-top: -100px;z-index: 2;position: relative;">
      <div class="container fw-lighter  " style="max-width: 1024px">
           <h4><strong style="color: var(--deep-blue-color)">PremierDev Hackathon</strong></h4>
            <div style="width: 20%;height: 3px;background-color: var(--orange-color)" class="mb-2"></div>
<!--            theteam-->
            <p class="mt-3" style="padding-right: 40px">
              Didn't know about our hackathon? These are quick facts that explain to you how it works. 
            </p>


            <div class="row">
              <div  class="col-sm-12 col-md-6 col-lg-4 mb-4"  v-for="(slider,i) in theteam" :key="'theteam_'+i" >
                <div class="abt-hkton">
                  <div class="abt-hkton-1" :style="{backgroundImage: `url(${slider.image})`}">

                  </div>
                  <div class="abt-hkton-2">
                    <h6 style="color:var(--orange-color)"><b>{{slider.name}}</b></h6>
                    <p v-html="slider.desc" style="margin:0;font-size:11pt;font-weight:lighter"></p>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <br/>
            <br/>








        <div class="row">
          <div class="col-sm-12 col-md-6 col-sm-7 px-3 d-flex flex-column justify-content-center align-items-start">
            <h4><strong style="color: var(--deep-blue-color)">PremierDev Hackathon</strong></h4>
            <div style="width: 20%;height: 3px;background-color: var(--orange-color)" class="mb-2"></div>
<!--            theteam-->
            <p class="mt-3" style="padding-right: 40px">
              Didn't know about our hackathon? These are quick facts that explain to you how it works. 
            </p>
            <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSecc_RST3uaQ-n85vBa1zoP805d0NYIAlvpAiME8b3aesyVDA/viewform" class="btn text-white px-4 py-2 mt-2" style="background: var(--deep-blue-color)">Apply Now <i
                class="mx-2 fa fa-chevron-right"></i></a>
          </div>
          <div class="col-sm-12 col-md-6 col-sm-7 px-2">
           
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
    <div class="bg-white">
      <div class="w-100" v-for="(slider,i) in studenlist" :key="'sliderBlur_'+i" >
        <div class="blur-bg-image _background animate__animated animate__fadeIn" style="z-index: 0!important;position: relative" :style="{backgroundImage: `url(${slider.image})`}" v-if="studen_pos===i">

        </div>
      </div>


        <!-- <br/>
        <br/>
        <br/> -->
        <!-- <Bizarresvg color="#151515" svgclass="w-100 image-design1 imd1"/> -->
        <div style="margin-top: -2em" class="_background bg-image">
          <div class="container py-5" style="max-width: 960px">
           <center>
             <h3 class="" style="color: var(--orange-color);margin-top: -3em;">Want to apply for the 2022 Tech Solution Challenge? Get started just now</h3>
<!--             <p class="text-white">-->
             <p class=" mt-4" style="max-width: 900px;color:var(--deep-blue-color)">You'll just need to fill the form present on the link below to get started</p>


             <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSecc_RST3uaQ-n85vBa1zoP805d0NYIAlvpAiME8b3aesyVDA/viewform" class="btn text-white px-4 py-2 mt-4" style="background: var(--orange-color)">Apply Now <i
                 class="mx-2 fa fa-chevron-right"></i></a>
             <br>
             <br>
             <br>
             <br>
             <br>
           </center>

          </div>
        </div>

    </div>
      <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Bizarresvg color="#151515" svgclass="w-100 image-design1 imd2"/>
        <div style="background-color:#151515;margin-top: -2em" class="_background bg-image">
          <div class="container">
            <h3 class="text-decoration-underline" style="color: var(--orange-color)">Frequently Asked Questions</h3>
            <div class="row">
              <div class="col-sm-12 col-md-4">
                  <!--silent :|-->
              </div>
              <div class="col-sm-12 col-md-8">
                <div class="mt-5">
                  <div v-for="faq in faqs" :key="faq.a">
                    <p class="faq-question text-white" @click="current_faq_answer = faq.a"><span>{{current_faq_answer === faq.a?'-':'+'}}</span>{{faq.q}}</p>
                    <div class="faq-answer animate__animated animate__fadeIn" v-if="current_faq_answer === faq.a">
                      {{faq.a}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    <Footer :notbizarre="true"/>
  </div>
</template>

<script>

import Header from "@/components/Header";
import Bizarresvg from "@/components/Bizarresvg";
import Footer from "@/components/Footer";

import {dataAxios, link} from '../../config/helpers'

export default {
  name: 'Home',
  components: {
    Footer,
    Bizarresvg,
    Header,
  },
  data() {
    
    return {
      theteam: [],faqs:[],current_faq_answer:null

    }
  },
  mounted() {
    const context=this;
    dataAxios('hackathon', (res)=>{
      context.theteam = res.data.map(hackathon=>{
        return{
          name:hackathon.title,
          desc:hackathon.subtitle,
          image:link.replace('api/', 'storage/')+hackathon.image.split("\\").join("/")
        }
      })
    setTimeout(function(){
      context.initSlick();
    },500)

        dataAxios('questions', (res)=>{
          context.faqs = res.data.map(questions=>{
            return{
              q:questions.question,
              a:questions.response
            }
          })
        })
    })
     setTimeout(function(){
      window.$('.imd1').css('margin-top',(0-window.$('.imd1').height()-20))
      window.$('.imd2').css('margin-top',(0-window.$('.imd2').height()-20))
    },100)
    window.onresize = function(){
      context.initImd1Responsive();
    }
  },
  methods: {
    initImd1Responsive(){ //to ensure that the responsive of the svg design of slider is okay
        window.$('.imd1').css('margin-top',(0-window.$('.imd1').height()-20))
      window.$('.imd2').css('margin-top',(0-window.$('.imd2').height()-20))
    },
    initSlick(){
      // let context=this;
      window.$('.slickers_team').slick({
        dots: false,
        infinite: true,
        // speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        // centerPadding: '40px',
        focusOnSelect: true,
        // autoplay:true,
        // autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // infinite: true,
              // dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });
    }
  }
}
</script>
<style scoped>

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}
.page-head{
  margin-top:-150px;
  position:relative;
  background-size: cover;
  background-repeat: no-repeat;background-position:center;
  height: 700px;
}
.page-head::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #151515;
  opacity: 0.79;
}
.content-section {
  height: calc(100% - 100px);
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.image-design1 {
  margin-top: -200px;
  z-index: 0;
  position: relative;
}

.bg-image {
  padding-top: 5.9em;
  margin-top: -5.4em;
  z-index: 2;
  position: relative;
  background-image: url("../assets/Frame 1.svg");
}




.slick-track{
  margin:30px!important;
}


.slickTeamItem{
  height:450px;
  width:200px;
  /*margin:10px;*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 0.6s;
}
.slickTeamItem >div{
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 30, 60, 0.6);
  transition: all ease 0.6s;
  opacity: 0;
  justify-content: center;
  align-items: center;

}
.slickTeamItem > div *{
  color:white;
}

.slickTeamItem:hover >div{
  opacity: 1;
}

.slick-prev::before, .slick-next::before{
  background-color: red!important;
}
.slickers_team button::before{
    color: var(--deep-blue-color) !important;
}


#vid {
  /* override other styles to make responsive */
  width: 100%    !important;
 height: 680px   !important;
  position: absolute;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/assets/home-slider/new/IMG_0061.jpeg');
}
.faq-question{
  cursor: pointer;
   color: white;
}
.faq-question span{
  color: var(--orange-color);
  margin-right: 0.8em;
  font-weight: bolder;
  font-size: 1.2em;
}
.faq-answer{
  opacity: 0.6!important;
 color: white;
  border-left: 1px solid var(--orange-color);
  padding: 1em;
  margin-bottom: 2em;
  margin-left: 1em;
}
.abt-hkton{
  margin-top: 40px;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 0px 24px 0px rgba(0, 30, 60, 0.1);
  display: flex;
  flex-direction: column;
}

.abt-hkton-1{
  border-radius: 15px 15px 0 0;
    min-height: 300px;
    background-repeat: no-repeat;
    background-size: cover;

}
.abt-hkton-2{
  flex-grow: 1;
    padding: 13px 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: rgba(255,255,255,0.5);
    border-radius: 0 0 15px 15px;
    justify-content: center;
}

</style>
