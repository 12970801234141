<template>
  <div class="home">

    <div class="topBackgroundVideo">
      
      <Header/>
      <div class="slider-container" style="margin-top:-65px;z-index: 1;position:relative">
        <div class="slide" v-for="(slider,i) in sliders" :key="'slider_'+i"
             :style="{backgroundImage: `url(${slider})`}"></div>
        <div class="overlay"></div>
        <div class="content-section container">
          <div class="row" style="height: 100%">
            <div
                class="col-xl-4 col-lg-6 col-md-8 col-sm-12 d-flex flex-column justify-content-center align-items-start"
                style="height: 100%">
              <h2 class="fw-bolder text-white" style="margin-top:-2em" id="main-home-name">
                <img src="../assets/premierdev-logo.png" height="130" style="margin-left:-40px;"/>
              </h2>
              <p class="text-white fw-light" style="max-width: 400px">
                Upskilling young Africans to find software solutions to the continent's most pressing challenges
              </p>
              <a href="#s2" class="btn text-white px-4 py-2 mt-2" style="background: var(--orange-color)">Explore Now <i
                  class="fa fa-chevron-right"></i></a>
            </div>
            <div class="col-xl-8 col-lg-6 col-md-4 col-sm-12 d-flex justify-content-center align-items-center"
                 style="height: 100%">
              <div style="max-width: 600px;padding:10px">
                <div class="slickers">

                  <div class="slickItem" v-for="(slider,i) in sliders" :key="'slider_'+i"
                       :style="{backgroundImage: `url(${slider})`}"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="controls-container">
          <div class="control" v-for="(slider,i) in sliders" :key="'controls_'+i"></div>
        </div>
      </div>
      <Bizarresvg color="#fff" class="w-100 image-design1 imd1"/>
    </div>
    <div class="bg-image _background bg-white">
      <div class="container py-2">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div class="_background w-100 c1 py-2" style="min-height: 400px;background-attachment: unset">

            </div>
          </div>
          <div class="col-sm-11 col-md-7 d-flex justify-content-center flex-column py-4 mb-5" id="s2">
            <h2><strong class="" style="color: var(--deep-blue-color)">Africa is set for software solutions</strong>
            </h2>
            <br/>
            <p class="fw-lighter">UN projections hold that Africa will be home to over 1.65 billion people by 2030, with
              the potential to own the largest workforce on the globe. Connecting and meeting the needs of every single
              African on the continent over the next decade is therefore the most ambitious business venture in sight.
              While structural infrastructure remains essential, software solutions have proven to amplify - and more
              often than not yield - effective results.</p>

            <p class="fw-lighter mb-5">Upskilling young Africans with digital skills to find software solutions to the
              challenges around the continent is hence not only an effective approach to meeting the Sustainable
              Development Goal targets, but equally one that will yield the most economic benefits to businesses.</p>
          </div>
          <div class="col-sm-1 col-md-1">
            <div class="h-100"
                 style="border-right: 3px solid var(--orange-color);border-top: 3px solid var(--orange-color);border-bottom: 3px solid var(--orange-color);">

            </div>
          </div>
        </div>
      </div>
      <br/>
      <Bizarresvg color="var(--orange-color)"/>
    </div>
    <div style="background-color: var(--orange-color);">
      <div style="height:30px">

      </div>
      <Bizarresvg color="rgba(0, 30, 60, 1)"/>
    </div>

    <div class="container" style="margin-top: -280px;z-index: 2;position: relative;">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="_background w-100 c12 h-100" style="min-height: 400px;background-position:top;background-attachment: unset;background-image:url('/assets/home-slider/00eb7f11-356f-4ef7-8a0d-6fc9897bc713.JPG')!important">

          </div>
        </div>
        <div class="col-sm-11 col-md-7 d-flex justify-content-center flex-column">
          <!--          <h2><strong class="" style="color: var(&#45;&#45;deep-blue-color)">Africa is set for software solutions</strong></h2>-->
          <br/>
          <br/>
          <br/>
          <p class=" fs-4 text-white">
            We seek to train young people on how to develop innovative software that is highly relevant for their
            community's immediate needs</p>
          <br/>
          <br/>
          <br/>

          <p class="fw-lighter fs-5 text-white">While software solutions like mobile banking have succeeded in other
            parts of the world, these continue to fail woefully in Africa. Solutions of African origin like Mobile
            Money, however, triumph spectacularly. This speaks of the uniqueness of African challenges and the
            difference in customers’ needs and priorities; Western-style e-commerce struggle on the continent. There is
            therefore the need for African inputs in addressing its challenges. Sadly, digital literacy on the continent
            lags behind the rest of the world.</p>
        </div>
        <div class="col-sm-1 col-md-1">
          <!--          <div class="h-100" style="border-right: 3px solid var(&#45;&#45;orange-color);border-top: 3px solid var(&#45;&#45;orange-color);border-bottom: 3px solid var(&#45;&#45;orange-color);">

                    </div>-->
        </div>
      </div>
    </div>

    <div class="dbg-img _background mt-5" style="background-image:url('/assets/home-slider/other/IMG_0124.jpeg')">
      <div>
        <Bizarresvgreversed color="rgba(0, 30, 60, 1)"/>
        <div class="container" style="max-width: 960px;">
          <center><h5 style="color: var(--orange-color)">Our programme places a strong emphasis on project-based
            learning</h5>
            <div style="height: 5px;width: 200px; background-color: var(--orange-color);"></div>
            <strong class="mt-4"><p class="text-white mt-4">“If we teach today’s student, as we taught yesterday's, we
              rob them of tomorrow” – John Devey.</p></strong>
          </center>
          <p class="text-white fw-lighter ">Software development is dynamic, challenges evolve by the second; a
            comprehensive solution today might prove worthless tomorrow. Reason why we focus on getting our trainees up
            to speed with the basics in the first few weeks and give them the hook to fish. We believe that the pressing
            challenges of the continent cannot be adequately addressed by working trainees through prescribed
            procedures; rather, we empower them to build their own procedures and adapt as situation demands. Our
            programme therefore provides trainees with the resources and support to learn through completing real-world
            projects.</p>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <!--       <Bizarresvg color="#fff" class="mt-4"/>-->
      </div>
    </div>
    <div style="background: white">
      <div class="_background _ttyle2 position-absolute" style="width: 100%;height:820px;opacity: 0.18">

      </div>
      <br/>
      <br/>
      <br/>

      <div class="row container ">
        <div class="col-sm-12 col-md-5 " style="min-height: 260px;">
          <div class="circle-component">
            <div>
              <div class="_background">

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-7">
          <h4><strong style="color: var(--orange-color)">We work with technically competent young Africans to find
            better, far-reaching solutions</strong></h4>
          <p class="fw-lighter mt-4">Stand-alone software solutions often struggle to get noticed in the crowded
            tech-space. Even worse, most impressive software solutions of African origin fail to capture their target
            markets, already crowded with multi-billion tech giants.</p>

          <p class="fw-lighter"> We therefore are building a symbiotic community of young African developers at home and
            abroad to effectively exchange ideas and synergise for better, far-reaching results.</p>
        </div>
      </div>
      <div style="height: 40px"/> <!--spacer-->
      <div class="container mt-5 pb-5">

        <p class="fw-lighter ">Our organisation has a bi-faceted approach to achieving our aims</p>

        <strong style="color:var(--orange-color);">PREMIERDEV UPSKILLS</strong>

        <p class="fw-lighter">This features our 2 annual flagship programmes:</p>


        <strong>a) PremierDev Technology Solution Challenge:</strong>

        <p class="fw-lighter"> This is an annual high-profile competition with over 50 participating university-level
          Cameroonians from across the country with the aim to spark an interest in digital technology and
          entrepreneurship.
        </p>

        <strong>b) PremierDev Technology Summer Bootcamp:</strong>

        <p class="fw-lighter"> A three-month project-oriented bootcamp on Software Development, followed by a month of
          software development projects for 20 promising young Cameroonians. Held over the summer each year, the
          bootcamp is central to our mission.
        </p>
        <strong style="color:var(--orange-color);">PREMIERDEV WORKS</strong>

        <p class="fw-lighter"> Our lone programme here is the PremierDev Software Project Workspace. On this programme,
          we work with technically competent students to find software solutions to the most pressing challenges around
          Cameroon and beyond.
        </p>

      </div>
    </div>
    <div style="background: #EAEAEA;margin-top:-16px;" class="p-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-4 d-flex justify-content-center flex-column">
              <h4 style="color: var(--orange-color)">
                Our programmes are proudly sponsored by:</h4>
          </div>
          <div class="col-sm-1 col-md-1 ">
            <div class="h-100"
                 style="border-left: 3px solid var(--orange-color);border-top: 3px solid var(--orange-color);border-bottom: 3px solid var(--orange-color);">

            </div>
          </div>
          <div class="col-sm-11 col-md-7 ">
            <div class="slick-immm">
              <img   v-for="(spon,i) in sponsors" :key="'spon'+i" :src=spon.image />
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-sm-12 col-md-4 d-flex flex-column justify-content-center">
              <h4 style="color: #77726E" class="">
                We also extend our gratitude to our local partners:</h4>
          </div>
          <div class="col-sm-12 col-md-8 ">

            <div class="slick-immm">
              <img  v-for="(org,i) in organisations" :key="'org'+i" :src=org.image />
            </div>
          </div>
        </div>
<br/>
<br/>
<br/>
      </div>
    </div>
    <Footer  notbizarre wContact/>
  </div>
</template>

<script>

import Header from "@/components/Header";
import Bizarresvg from "@/components/Bizarresvg";
import Bizarresvgreversed from "@/components/Bizarresvgreversed";
import Footer from "@/components/Footer";

import {dataAxios, link} from '../../config/helpers'

export default {
  name: 'Home',
  components: {
    Footer,
    Bizarresvg,
    Header,
    Bizarresvgreversed
  },
  data() {
    return {
      sliders: [
        '/assets/home-slider/new/IMG_0170_.jpeg',
        '/assets/home-slider/new/IMG_0403.jpeg',
        '/assets/home-slider/new/IMG_0449.jpeg',
        // '/assets/home-slider/s3.jpg',
        // '/assets/home-slider/s4.jpg',
        // '/assets/home-slider/s5.jpg',
        // '/assets/home-slider/s1.jpg',
      ],
      slickObject: null,
      sponsors: [],
      organisations: []
    }
  },
  mounted() {
    const context=this;
    dataAxios('sponsors', (res)=>{
      this.sponsors = res.data.map(spon=>{
        return{
          image: link.replace('api/', 'storage/')+spon.image.split("\\").join("/")
        }
      })
      dataAxios('organisations', (resp)=>{
        this.organisations = resp.data.map(organ=>{
          return{
            image: link.replace('api/', 'storage/')+organ.image.split("\\").join("/")
          }
        })
      })
      setTimeout(function(){
        context.initSlick();
      },500)
    })
     context.initSlider();
    context.initImd1Responsive();
     window.onresize = function(){
        context.initImd1Responsive();
      }
  },
  methods: {
    initImd1Responsive(){ 
      //to ensure that the responsive of the svg design of slider is okay
     window.$('.imd1').css('margin-top',(0-window.$('.imd1').height()-200))
    },
    initSlick() {
       this.slickObject = window.$('.slickers').slick({
        dots: false,
        infinite: true,
        // speed: 4000,
        slidesToShow: 1.75,
        slidesToScroll: 1,
        centerMode: true,
        autoplay:false,
        centerPadding: '60px',
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              // infinite: true,
              // dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });

      window.$('.slick-immm').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        autoplay:true,
        slidesToScroll: 1,centerMode: true,
        variableWidth: true,
        centerPadding:'60px',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              // infinite: true,
              // dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });
      window.$('.slickers').on('swipe', function () {
        console.log("SWIPE");

        // global.activeSlide = nextSlide;
        // global.changeSlides();
        // console.log(direction);
        // left
      });
    },
    initSlider() {
      const context = this;
      //slider https://codepen.io/FlorinPop17/pen/gOpWwrg
      const slides = document.querySelectorAll('.slide');
      const controls = document.querySelectorAll('.control');
      global.activeSlide = 0;
      let prevActive = 0;

      changeSlides();
      let int = setInterval(changeSlides, 4000);

      function changeSlides() {
        slides[prevActive].classList.remove('active');
        controls[prevActive].classList.remove('active');

        slides[global.activeSlide].classList.add('active');
        controls[global.activeSlide].classList.add('active');
        if (context.slickObject) {
          // const sld =((global.activeSlide-1)<0)?(slides.length-1):(global.activeSlide-1);
          console.log(global.activeSlide," is the next slide")
          // context.slickObject.slickGoTo(global.activeSlide);
          window.$('.slickers').slick('slickGoTo', global.activeSlide);
          
        }
        prevActive = global.activeSlide++;
        if (global.activeSlide >= slides.length) {
          global.activeSlide = 0;
        }

console.log(prevActive,global.activeSlide,slides.length)

        // console.log(prevActive, global.activeSlide);
      }

      global.changeSlides = changeSlides;
      controls.forEach(control => {
        control.addEventListener('click', () => {
          let idx = [...controls].findIndex(c => c === control);
          global.activeSlide = idx;

          changeSlides();

          clearInterval(int);
          int = setInterval(changeSlides, 4000);
        });
      });
    }
  }
}
</script>
<style scoped>
.bg-image {
  padding-top: 5.9em;
  margin-top: -7.4em;
  z-index: 2;
  position: relative;
  background-image: url("../assets/Frame 1.svg");
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: 110vh;
  width: 100vw;
}

.slider-container h1 {
  color: #fff;
  font-size: 100px;
  letter-spacing: 5px;
  position: relative;
  z-index: 100;
  text-align: center;
}

.slider-container::after {
  background-color: #000;
  content: '';
  position: absolute;
  opacity: 0.3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  transform: scale(1.15);
  transition: opacity .6s ease;
}

.slide.active {
  animation: grow 4s linear forwards;
  opacity: 1;
}

@keyframes grow {
  0%, 20% {
    transform: scale(1);
  }

  75%, 100% {
    transform: scale(1.15);
  }
}

.controls-container {
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
  z-index: 2;
}

.control {
  background-color: #fff;
  cursor: pointer;
  opacity: 0.5;
  margin: 6px;
  height: 40px;
  width: 5px;
  transition: opacity 0.3s, background-color 0.3s, transform 0.3s;
}

.control.active, .control:hover {
  background-color: #fff;
  opacity: 1;
  transform: scale(1.2);
}

.content-section {
  height: calc(100% - 150px);
  z-index: 3;


}

.overlay {
  background-color: var(--orange-color);
  opacity: 0.15;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.image-design1 {
  margin-top: -300px;
  z-index: 2;
  position: relative;
}

.slickItem {
  height: 300px;
  width: 200px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 0 17px 1px rgba(0, 0, 0, 20%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 0.6s;
}

.slick-center {
  transform: scale(1.05);
}

.slick-track {
  margin: 30px !important;
}

.c1,.c12 {
  /*margin-top: 40px;*/
  border-radius: 10px;
  background-image: url("/assets/home-slider/other/IMG_0015.jpeg");
}

.dbg-img,.c12 {
  background-image: url("https://images.unsplash.com/photo-1496181133206-80ce9b88a853?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80");
}

.dbg-img > div {
  background-color: rgba(0, 30, 60, 0.8);
}

.circle-component {
  border-radius: 50%;
  height: 350px;
  width: 350px;
  background-color: var(--orange-color);
  padding: 10px;
  margin-left: -100px;
  margin-top: -100px;
  position: absolute;
}

.circle-component > div {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  border: 5px solid white;
  padding: 10px;
}

.circle-component > div > div {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  background-attachment: unset;
  background-image: url("/assets/home-slider/other/IMG_0179.jpeg");
}

._ttyle2 {
  background-image: url("../assets/Print-a-Wallpaper.jpeg");


}

.home {
  background: rgba(0, 30, 60, 1);
}
.slick-immm{
  padding: 30px 0;
}
.slick-immm img{
  height: 100px!important;
  padding: 10px 20px;
  /*padding*/
}
#main-home-name{
  font-size: 4em;
}
/* Responsive manager*/
@media only screen and (max-width:410px) {
    #main-home-name{
      font-size: 2em!important;
     
    }
  }

</style>
